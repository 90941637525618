import React, { useState, useEffect } from "react";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";
import { useNotification } from "../../../common/hooks/useNotification";

function CreateGroupModal({ setCreateGroupOpen, onClose }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const { sendRequest } = useHttp();
  const { authData } = useAuth();
  const [userNames, setUserNames] = useState([]);
  const notify = useNotification();

  const isCreateButtonDisabled = !groupName || selectedUsers.length === 0;

  useEffect(() => {
    fetchUsers();
  }, [authData]);

  // Fetch existing organization groups
  // const fetchGroups = async () => {
  //   try {
  //     await sendRequest(
  //       {
  //         url: "v1/organization/groups",
  //         accessToken: authData.accessToken,
  //         headers: {
  //           userName: authData.userName,
  //           organizationId: authData.organizationId,
  //           deviceId: authData.deviceId,
  //         },
  //       },
  //       (response) => {
  //         setGroups(response.data.organizationGroups);
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error getting groups:", error);
  //   }
  // };

  // Fetch available users for selection
  const fetchUsers = async () => {
    try {
      await sendRequest(
        {
          url: `v1/organization/users?page=0&size=5`,
          method: "GET",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            const users = response.data.organizationUsers.map(
              (organizationUser) =>
                `${organizationUser.user.firstName} ${organizationUser.user.lastName}`
            );
            setUserNames(users);
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Create a new group
  const handleCreateGroup = async () => {
    try {
      await sendRequest(
        {
          url: "v1/group/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            groupName,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            const newGroup = { groupName };
            setGroups((prevGroups) => [...prevGroups, newGroup]);
            setGroupName("");
            setIsDropdownOpen(false);
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  // Toggle user selection for the group
  const toggleUserSelection = (user) => {
    setSelectedUsers((prev) =>
      prev.includes(user) ? prev.filter((u) => u !== user) : [...prev, user]
    );
  };

  const handleCloseModal = () => {
    setCreateGroupOpen(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start pt-6 justify-center z-40 px-4 sm:px-0">
      <div className="text-start bg-white rounded-lg w-full max-w-md p-6 md:p-8 relative">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Create Group</h2>

        {/* Group Name */}
        <div className="mb-4">
          <label className="text-md font-medium text-gray-700">Group Name</label>
          <input
            type="text"
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Add Users Dropdown */}
        <div className="relative mb-4">
          <label className="text-md font-medium text-gray-700">Add Users</label>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 text-left flex justify-between items-center"
          >
            <span>{selectedUsers.length > 0 ? selectedUsers.join(", ") : "Select users..."}</span>
          </button>
          {isDropdownOpen && (
            <div className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg p-4">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full border rounded-md py-2 px-3 mb-2"
              />
              {userNames
                .filter((user) => user.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((user, index) => (
                  <div key={index} className="flex items-center space-x-2 mt-2">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user)}
                      onChange={() => toggleUserSelection(user)}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-gray-700">{user}</span>
                  </div>
                ))}
              <div className="flex justify-end mt-3">
                <button onClick={() => setIsDropdownOpen(false)} className="bg-blue-600 text-white px-3 py-2 rounded-lg">
                  Done
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="border border-gray-400 rounded-lg py-2 px-5"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateGroup}
            className={`bg-blue-600 text-white py-2 px-5 rounded-lg ${
              isCreateButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isCreateButtonDisabled}
          >
            Create Group
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateGroupModal;
