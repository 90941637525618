import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { FaMountainSun } from "react-icons/fa6";
import { useApi } from "../../../context/OrganizationContaxt";
import { Link } from "react-router-dom";
import { BiCross } from "react-icons/bi";
import { FaCog } from "react-icons/fa";

const Archieve = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // const toggleFilterDropdown = () => {
  //   setIsFilterOpen(!isFilterOpen);
  // };
  const [search, setSearch] = useState('');
  const { InspectionLists } = useApi();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const dropdownContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false); // Controls "Select All"
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Option 1");

const toggleOptionsMenu = (index) => {
  setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
};

const handleDocumentClick = (event) => {
  if (!event.target.closest(".dropdown-container")) {
    setOpenDropdownIndex(null);
  }
};

useEffect(() => {
  document.addEventListener("click", handleDocumentClick);
  return () => {
    document.removeEventListener("click", handleDocumentClick);
  };
}, []);


  
  const [currentSelection, setCurrentSelection] = useState(null); // Track the selected option
  const initialOptions = [
    { id: 1, label: "Inspection", underline: true, checked: false },
    { id: 2, label: "Actions", underline: false, checked: false },
    { id: 3, label: "Doc number", underline: false, checked: false },
    { id: 4, label: "Score", underline: true, checked: false },
    { id: 5, label: "Conducted", underline: true, checked: false },
    { id: 6, label: "Completed", underline: true, checked: false },
  ];
  
  const [options, setOptions] = useState(initialOptions);
  const [filteredOptions, setFilteredOptions] = useState(initialOptions);  
    const toggleCheckbox = (id) => {
      setFilteredOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, checked: !option.checked } : option
        )
      );
    };

    const toggleDropdown = () => {
      setIsOpen((prev) => !prev); // Toggles the dropdown open/close
    };

    const handleSelectionChange = (option) => {
      setSelectedOption(option);
      setIsOpen(false); // Close the dropdown after selection (if needed)
    };
    
    const handleClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };
  
    useEffect(() => {
      if (isOpen) {
        document.addEventListener("mousedown", handleClick);
      } else {
        document.removeEventListener("mousedown", handleClick);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [isOpen]);

    const toggleSettingsDropdown = () => {
      setIsSettingsOpen(!isSettingsOpen);
    };
    

  // const toggleDropdown = () => {
  //   setIsDropdownOpen((prev) => !prev);
  // };

  const addFilter = (filter) => {
    setSelectedTemplates([...selectedTemplates, filter]);
    setIsDropdownOpen(false); // Close the dropdown when a filter is selected
  };

  const removeFilter = (index) => {
    setSelectedTemplates(selectedTemplates.filter((_, i) => i !== index));
  };

  const clearFilter = () => {
    setSelectedTemplates([]);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If selecting all, add all items to selected
      const allIds =
        filterSearch?.length > 0
          ? filterSearch.map((_, index) => index)
          : InspectionLists?.map((_, index) => index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]); // Deselect all
    }
  };

  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((id) => id !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

    const handleOutsideClick = (event) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown if clicked outside
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);
  
  const toggleDropdownVisibility = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const filterSearch = InspectionLists?.filter((item) =>
    (item?.title || "").toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    console.log("InspectionLists:", InspectionLists);
    console.log("Search value:", search);
    console.log("Filtered Results:", filterSearch);
  }, [search, InspectionLists]);

  // const filterSearch = InspectionLists?.filter((item) =>
  //   item?.title?.toLowerCase().includes(search.toLowerCase())
  // );
  return (
    <div className=" pt-5 pe-10">
      <h1 className="text-gray-800 font-bold text-2xl text-start ">
        Inspections
      </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          {/* Search and filter */}
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
                <FaSearch className="text-gray-500" />
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                  placeholder="Search"
                  className="ml-2 outline-none bg-transparent"
                />
              </div>
            <div className="flex gap-5 text-center" ref={dropdownContainerRef}>
          
          <button
            onClick={toggleDropdownVisibility}
            className="text-[#6559ff] hover:text-blue-600 hover:bg-indigo-50 px-2 py-2 rounded-md"
          >
            + Add filter
          </button>

          <div className="flex gap-2">
            {selectedTemplates?.map((item, index) => (
              <button
                key={index}
                onClick={() => removeFilter(index)}
                className="bg-blue-100 gap-3 text-center items-center text-blue-800 text-xs font-medium flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
              >
                {item}
                <BiCross />
              </button>
            ))}
          </div>

          {isDropdownOpen && (
            <div className="absolute mt-2 h-80 overflow-scroll w-48 rounded-md shadow-lg bg-white">
              <ul className="py-1 text-start text-gray-700">
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter1")}
                >
                  Filter1
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter2")}
                >
                  Filter2
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter3")}
                >
                  Filter3
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter4")}
                >
                  Filter4
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter5")}
                >
                  Filter5
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter6")}
                >
                  Filter6
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addFilter("Filter7")}
                >
                  Filter7
                </li>
              </ul>
            </div>
          )}
           </div>
          </div>          
          <div className="flex items-center space-x-4">
            <div className="text-gray-700 text-sm mt-4 sm:mt-0 flex justify-end items-center">
              <p className="text-gray-600 text-md">
                {filterSearch.length === 0 
                  ? "No results" 
                  : `1 - ${filterSearch.length} of ${filterSearch.length} result`}
              </p>              <span className="ml-4 relative">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="icon-button"
                >
                  <svg width="18" height="18" viewBox="0 0 14 14">
                    <g transform="translate(5.542 1.458)" fill="#1f2533" fillRule="nonzero">
                      <circle transform="rotate(90 1.458 5.542)" cx="1.458" cy="5.542" r="1.458"></circle>
                      <circle transform="rotate(90 1.458 9.625)" cx="1.458" cy="9.625" r="1.458"></circle>
                      <circle transform="rotate(90 1.458 1.458)" cx="1.458" cy="1.458" r="1.458"></circle>
                    </g>
                  </svg>
                </button>

                {isOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute mt-1 w-64 right-1 bg-white shadow-lg rounded-md p-4 z-10"
                  >
                    <ul className="text-left">
                      <p>Results per page</p>
                      <li 
                        onClick={() => handleSelectionChange("Option 1")} 
                        className="px-4 py-2 hover:bg-indigo-50 cursor-pointer items-center"
                      >
                         {selectedOption === "Option 1" && <span className="text-blue-500 font-bold">✓</span>} 25
                      </li>
                      <li 
                        onClick={() => handleSelectionChange("Option 2")} 
                        className="px-4 py-2 hover:bg-indigo-50 cursor-pointer items-center"
                      >
                         {selectedOption === "Option 2" && <span className="text-blue-500 font-bold">✓</span>} 50
                      </li>
                      <li 
                        onClick={() => handleSelectionChange("Option 3")} 
                        className="px-4 py-2 hover:bg-indigo-50 cursor-pointer items-center"
                      >
                         {selectedOption === "Option 3" && <span className="text-blue-500 font-bold">✓</span>} 100
                      </li>
                    </ul>
                  </div>
                )}
              </span>
            </div> 
          </div>
        </div>
        <div
          className={`flex items-center border-2 px-2 py-3 text-gray-500 text-sm font-medium ${
            selectedItems.length > 0 ? "border-blue-500" : "border-gray-300"
          }`}
        >
          <input
            type="checkbox"
            className="mr-4"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          {options.map((option, index) => (
            <span
              key={index}
              className={`flex-grow text-gray-800 ${
                option.underline ? "underline" : ""
              }`}
            >
              {option.label}
            </span>
          ))}
          <th className="p-2 flex-1 text-left font-medium">
            <div className="flex justify-end items-center">
              <button onClick={toggleSettingsDropdown} className="relative">
                <FaCog className="text-gray-500 text-xl" />
              </button>
            </div>
            {isSettingsOpen && (
              <div
                className="w-72 border rounded-lg shadow-sm bg-white p-4"
                style={{ position: "absolute", right: "100px" }}
              >
                <div className="overflow-y-auto max-h-60">
                  <ul>
                    {filteredOptions.map((item) => (
                      <li
                        key={item.id}
                        className="flex items-center mb-2 hover:bg-gray-100 rounded-md p-1 transition"
                      >
                        <label className="flex items-center cursor-pointer w-full">
                          <input
                            type="checkbox"
                            checked={item.checked}
                            onChange={() => toggleCheckbox(item.id)}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 rounded"
                          />
                          <span className="ml-3 text-gray-700">
                            {item.label}
                          </span>
                          {item.isNew && (
                            <span className="ml-auto bg-purple-100 text-purple-600 text-xs font-semibold px-2 py-0.5 rounded">
                              NEW
                            </span>
                          )}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </th>
        </div>

      {
        filterSearch?.length > 0 ? (
          filterSearch.map((item, index) => (
            <div
              key={index}
              className="flex mt-3 items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium"
            >
              <input
                type="checkbox"
                className="mr-4"
                checked={selectedItems.includes(index)}
                onChange={() => handleSelectItem(index)}
              />              
              <span className="w-1/5 text-gray-800 truncate">
                {item?.title || "No Title"}
              </span>
              <span className="w-1/5 text-gray-800">Actions</span>
              <span className="w-1/5 text-gray-800 truncate">
                {item?.documentNumber || "No Available"}
              </span>
              <span className="w-1/5 text-gray-800 truncate">
                {item?.score ? `${item.score}%` : "0%"}
              </span>
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.conductedAt || "Not Provided"}
              </span>
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.isCompleted ? "Completed" : "Not Completed"}
              </span>
              <div className="relative dropdown-container">
                <button
                  className="ml-4 text-gray-400 hover:text-gray-600"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents immediate closing
                    toggleOptionsMenu(index);
                  }}
                >
                  <svg width="1.125rem" height="1.125rem" viewBox="0 0 24 24" color="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5Z" fill="currentColor"></path>
                    <path d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z" fill="currentColor"></path>
                    <path d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="currentColor"></path>
                  </svg>
                </button>

                {openDropdownIndex === index && (
                  <div className="absolute right-0 mt-2 w-48 border rounded-lg shadow-lg bg-white text-left">
                    <ul>
                      <li className="p-2 hover:bg-gray-100">Edit inspection</li>
                      <li className="p-2 hover:bg-gray-100">Duplicate inspection</li>
                      <li className="p-2 hover:bg-gray-100">View report</li>
                      <li className="p-2 hover:bg-gray-100">View history</li>
                      <li className="p-2 hover:bg-gray-100">Archive</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : search && InspectionLists?.length > 0 ? (
          // Show "No results found" if search is active but no matches
          <div className="flex flex-col items-center justify-center py-16 border-2">
            <svg viewBox="0 0 138 138" fill="none" width="150" height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>            
            <h2 className="mt-4">No results</h2>
            <p className="text-gray-600 mt-2">Try again with different search terms or filters</p>
          </div>
        ) : InspectionLists?.length > 0 ? (
          // Render all items if no search or filters applied
          InspectionLists.map((item, index) => (
            <div
              key={index}
              className="flex mt-3 items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium"
            >
              <input type="checkbox" className="mr-4" />
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.title || "No Title"}
              </span>
              <span className="w-1/5 text-gray-800">Actions</span>
              <span className="w-1/5 text-gray-800 truncate">
                {item?.documentNumber || "No Available"}
              </span>
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.score || "N/A"}
              </span>
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.conductedAt || "Not Provided"}
              </span>
              <span className="w-1/5 text-gray-800 underline truncate">
                {item?.isCompleted ? "Completed" : "Not Completed"}
              </span>
            </div>
          ))
        ) : (
          // Render empty state when no inspections exist
          <div className="flex flex-col items-center justify-center py-16 border-2">
            <FaMountainSun className="text-[#4740d4] text-6xl mb-4" />
            <p className="text-gray-600 font-[600] ">
              Every inspection conducted by your team will appear here
            </p>
            <p className="text-gray-600 font-medium pt-1">
              There are no inspections yet,{" "}
              <Link to={"/dashboard/templates"} className="text-[#4740d4]">
                create a template to start inspections
              </Link>
            </p>
          </div>
        )
      }

        <div className="flex justify-end items-center mt-6 space-x-4">
          <button
            className="text-gray-500 bg-gray-200 border border-gray-400 rounded p-2"
            aria-label="Previous page"
          >
            &lt;
          </button>
          <span className="text-gray-600"> 1 / 1</span>
          <button className="text-gray-500" aria-label="Next page">
            &gt;
          </button>
        </div>

      {selectedItems.length > 0 && (
        <div className="flex justify-between items-center border-blue-500 bg-indigo-400 px-4 py-2">
          <span className="text-gray-700 font-medium">
            {selectedItems.length} inspection{selectedItems.length > 1 ? "s" : ""} selected
          </span>
          <div className="flex space-x-4">
            <button
              className="text-blue-500 font-medium"
              onClick={() => {
                setSelectAll(false);
                setSelectedItems([]);
              }}
            >
              Cancel
            </button>
            <button
              className="text-red-500 font-medium"
              onClick={() => alert("Delete functionality not implemented")}
            >
              Delete
            </button>
          </div>
        </div>
      )}



      {/* Empty State */}

      {/* Pagination */}
      {/* <div className="flex items-center justify-center space-x-4 mt-4">
      <button className="text-gray-400 hover:text-gray-600">&gt;</button>
        <button className="text-gray-400 hover:text-gray-600">1</button>
        <button className="text-gray-400 hover:text-gray-600">&gt;</button>
      </div> */}
    </div>
  );
};

export default Archieve;
