import { useApi } from "../../../context/OrganizationContaxt";
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNotification } from "../../../../common/hooks/useNotification";
import useHttp from "../../../../common/hooks/useHttp";
import { BiCross } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
const ScheduleImage = require("../../../../assets/schedule/2.jpg");
const Manage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [showSelectedTemplate, setShowSelectedTemplate] = useState([]);
  const { authData } = useAuth();
  const { schedules, getMyshedule, getTamplateList, getSchedualList } =
    useApi();
  const notify = useNotification();
  const [showTitle, setTitle] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { sendRequest } = useHttp();
  const [selectedPageSize, setSelectedPageSize] = useState(null);
  const dropdownRef = useRef(null);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);
  const [currentStatusSelection, setCurrentStatusSelection] = useState(null); // Add state for selected rows
  const statusDropdownRef = useRef(null); // Create a ref for the status dropdown container
  const [currentSelection, setCurrentSelection] = useState("Option 1"); // Default to "Option 1"


  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const handleStatusChange = (status) => {
    setCurrentStatusSelection(status);
    setIsStatusDropdownOpen(false); // Close dropdown after status selection
  };

  // Close the status dropdown when clicking outside of it
  const handleOutsideClick = (event) => {
      if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
        setIsStatusDropdownOpen(false); // Close the dropdown if clicked outside
      }
    };
  
    // Attach event listener on mount and remove it on unmount
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);
  

  const openDeleteConfirmationPopup = () => {
    setIsConfirmDeletePopupOpen(true); // Open delete confirmation popup
  };

  const confirmDeleteAction = () => {
    // Logic for deleting the selected rows
    setSelectedRows([]); // Clear selected rows after deletion
    setIsConfirmDeletePopupOpen(false); // Close popup after confirming
  };

  const cancelDeleteAction = () => {
    setIsConfirmDeletePopupOpen(false); // Close popup without deleting
  };

  const clearSelection = () => {
    setSelectedRows([]); // Clear selected rows when the cross icon is clicked
  };

  const handleSelectionChange = (option) => {
    setCurrentSelection(option); // Set the selected option
    setIsDropdownOpen(false); // Optionally close the dropdown after selection
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handlePageSizeSelection = (size) => {
    setSelectedPageSize(size);
  };
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const [searchValue, setSearchValue] = useState("");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false); // Close dropdown after selection
  };
  const handleRowClick = (id) => {
    console.log("Row clicked with ID:", id);
    // Perform the desired action, such as navigation or selection
  };
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const handleClearSearch = () => {
    setSearchValue("");
  };
  const handleSelectAllCheckbox = (e) => {
    if (e.target.checked) {
      // Select all rows
      const allRowIds = schedules?.data?.schedules.map((item) => item.id) || [];
      setSelectedRows(allRowIds);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to handle individual row checkbox changes
  const handleRowCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
    }
  };

  // Function to check if a row is selected
  const isRowSelected = (rowId) => {
    return selectedRows.includes(rowId);
  };

  const filteredData = schedules?.data?.schedules.filter(
    (item) =>
      item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.assigneeName?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const hanldleDelete = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/schedule/${id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              getMyshedule();
              getSchedualList();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const Resume = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/schedule/${id}/resume`,
          method: "PATCH",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              getSchedualList();
              getMyshedule();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const Pause = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/schedule/${id}/pause`,
          method: "PATCH",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              getSchedualList();
              getMyshedule();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleTemplateSelect = (templateTitle) => {
    setTitle(templateTitle);
    // Filter schedules based on the selected template's title
    const filtered = schedules?.data?.schedules.filter(
      (schedule) => schedule?.templateTitle === templateTitle
    );
    console.log(filtered);
    setSelectedTemplate(filtered);
    setShowSelectedTemplate(filtered);
    setIsFilterOpen(false); // Close the
  };
  const removeFilter = () => {
    setSelectedTemplate("");
    setShowSelectedTemplate([]);
    setTitle("");
  };
  return (
    <div
      role="tabpanel"
      className="px-2 sm:px-4 md:px-8 lg:px-0"
    >
      <div className="mt-6 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 justify-between" ref={dropdownRef}>
        <div className="flex items-center w-full sm:w-auto">
          {/* Search Section */}
          <div className="relative w-full sm:w-64">
            {/* Search Icon */}
            <svg
              width="21"
              height="21"
              viewBox="0 0 24 24"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2931 2.37207C5.96319 2.37207 2.45312 5.88213 2.45312 10.212C2.45312 14.5419 5.96319 18.052 10.2931 18.052C12.0558 18.052 13.6826 17.4702 14.992 16.4883L19.8393 21.3355C20.2298 21.726 20.863 21.726 21.2535 21.3355C21.644 20.945 21.644 20.3118 21.2535 19.9213L16.4271 15.0949C17.4949 13.7554 18.133 12.0582 18.133 10.212C18.133 5.88213 14.6229 2.37207 10.2931 2.37207ZM4.45312 10.212C4.45312 6.9867 7.06776 4.37207 10.2931 4.37207C13.5184 4.37207 16.133 6.9867 16.133 10.212C16.133 13.4373 13.5184 16.052 10.2931 16.052C7.06776 16.052 4.45312 13.4373 4.45312 10.212Z"
                fill="currentColor"
              ></path>
            </svg>

            {/* Search Input */}
            <input
              type="text"
              value={searchValue}
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              className="border rounded-md p-2 pl-10 w-full focus:border-indigo-500 focus:outline-none"
            />

            {/* Clear Icon (X) */}
            {searchValue && (
              <button
                onClick={handleClearSearch}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>

          {/* Filter Button and Filters */}
          <div className="flex gap-5 text-center ml-4">
            <button
              onClick={toggleFilterDropdown}
              className="text-[#6559ff] hover:text-blue-600 hover:bg-indigo-50 px-2 py-2 rounded-md"
            >
              + Add filter
            </button>
            {showSelectedTemplate?.length === 0 && (
              <>
                {showTitle === "" ? (
                  <></>
                ) : (
                  <button
                    onClick={removeFilter}
                    className="gap-3 text-center items-center text-blue-800 text-xs font-medium me-2 flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                  >
                    {showTitle}
                  </button>
                )}
              </>
            )}
            {showSelectedTemplate?.map((item, index) => (
              <button
                key={index}
                onClick={removeFilter}
                className="bg-blue-100 gap-3 text-center items-center text-blue-800 text-xs font-medium me-2 flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
              >
                {item?.title}
                <BiCross />
              </button>
            ))}
            {isDropdownOpen && (
              <div ref={dropdownRef} className="dropdown-menu">
                {/* Your dropdown content here */}
              </div>
            )}
          </div>
        </div>

        {/* Result Count on the Right */}
        <div className="text-gray-700 text-sm mt-4 sm:mt-0 flex justify-end items-center">
          <span>1 - 1 of 1 result</span>
          <span className="ml-4 relative">
            <button
              type="button"
              onClick={toggleDropdown}
              className="icon-button__StyledButton-iauditor__sc-1ev4xf3-1 cOPNnz"
            >
              <svg width="18" height="18" viewBox="0 0 14 14" focusable="false">
                <g transform="translate(5.542 1.458)" fill="#1f2533" fillRule="nonzero">
                  <circle transform="rotate(90 1.458 5.542)" cx="1.458" cy="5.542" r="1.458"></circle>
                  <circle transform="rotate(90 1.458 9.625)" cx="1.458" cy="9.625" r="1.458"></circle>
                  <circle transform="rotate(90 1.458 1.458)" cx="1.458" cy="1.458" r="1.458"></circle>
                </g>
              </svg>
            </button>

            {/* Dropdown */}
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute mt-1 w-64 right-1 bg-white shadow-lg rounded-md p-4 z-10"
              >
                <div className="space-y-4">
                  <ul className="text-left">
                    <label>Results per page</label>
                    <li
                      onClick={() => handleSelectionChange("Option 1")}
                      className="px-4 py-2 hover:bg-indigo-50 cursor-pointer flex items-center"
                    >
                      {currentSelection === "Option 1" && (
                        <FaCheck className="mr-2 text-indigo-500" />
                      )}
                      25
                    </li>
                    <li
                      onClick={() => handleSelectionChange("Option 2")}
                      className="px-4 py-2 hover:bg-indigo-50 cursor-pointer flex items-center"
                    >
                      {currentSelection === "Option 2" && (
                        <FaCheck className="mr-2 text-indigo-500" />
                      )}
                      50
                    </li>
                    <li
                      onClick={() => handleSelectionChange("Option 3")}
                      className="px-4 py-2 hover:bg-indigo-50 cursor-pointer flex items-center"
                    >
                      {currentSelection === "Option 3" && (
                        <FaCheck className="mr-2 text-indigo-500" />
                      )}
                      100
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </span>
        </div>     
        {/* Filter Dropdown */}
        {isFilterOpen && (
          <div className="absolute mt-2 h-80 overflow-scroll w-48 rounded-md shadow-lg bg-white">
            <ul className="py-1 text-start text-gray-700">
              {getTamplateList?.data?.templates.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleTemplateSelect(item?.title)}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {item?.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="mt-4 w-[150vh] rounded-md overflow-x-auto">
        <table className="w-full  text-left border border-gray-300">
        <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              <th className="p-2 text-xs sm:text-sm md:text-base">
                {/* <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                  onChange={(e) => handleSelectAllCheckbox(e)} // Function to handle select all
                  aria-label="Select all rows"
                /> */}
                <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                onChange={handleSelectAllCheckbox}
                aria-label="Select all rows"
                checked={
                  schedules?.data?.schedules.length > 0 &&
                  selectedRows.length === schedules?.data?.schedules.length
                }
              />
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Title</th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Assigned by</th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Assigned to</th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Status</th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Next due</th>
              <th className="p-2 text-xs sm:text-sm md:text-sm font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            {schedules ? (
              selectedTemplate === "" ? (
                searchValue === "" ? (
                  schedules?.data?.schedules.length > 0 ? (
                    schedules?.data?.schedules?.map((item, index) => (
                      <tr
                      key={index}
                      className={`pt-12 p-12 border border-gray-500 hover hover:bg-gray-100 ${isRowSelected(item?.id) ? 'bg-gray-100' : ''} cursor-pointer`}
                      onClick={() => handleRowClick(item?.id)} // Replace with your row click handler
                    >
                      <td className="p-2" onClick={(e) => e.stopPropagation()}>
                        {/* <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                          onChange={(e) => handleSelectAllCheckbox(e, item?.id)}
                          aria-label={`Select row for ${item?.title}`}
                        /> */}
                       <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                        onChange={(e) => handleRowCheckboxChange(e, item.id)}
                        checked={isRowSelected(item.id)}
                        aria-label={`Select row for ${item.title}`}
                      />
                      </td>
                      <td className="mt-12 p-2 relative group">
                        {item?.title}
                        <div className="absolute top-[-100%] left-1/2 transform -translate-x-1/2 flex items-center justify-center bg-black text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md px-4 py-2 z-10">
                          {item?.title}
                        </div>
                      </td>
                      <td className="truncate max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {authData?.userDetails?.firstName}
                      </td>
                      <td className="truncate max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {item?.assigneeName}
                      </td>
                      <td>
                        {item?.active === true ? (
                          <button
                            className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]"
                            aria-label="Set to Active"
                            onClick={(e) => e.stopPropagation()} // Prevent row click
                          >
                            Active
                          </button>
                        ) : (
                          <button
                            className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]"
                            aria-label="Set to No Active"
                            onClick={(e) => e.stopPropagation()} // Prevent row click
                          >
                            No active
                          </button>
                        )}
                      </td>
                      <td>{item?.nextDue}</td>
                      <td className="flex items-center justify-end mt-4" onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={() => Pause(item?.id)}
                          className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400"
                          aria-label="Pause"
                        >
                          Pause
                        </button>
                        <button
                          onClick={() => Resume(item?.id)}
                          className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                          aria-label="Resume"
                        >
                          Resume
                        </button>
                        <button
                          onClick={() => hanldleDelete(item?.id)}
                          className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                          aria-label="Delete"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center text-gray-600">
                        No data available
                      </td>
                    </tr>
                  )
                ) : filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index} className="pt-12 p-12">
                    <td className="p-2">
                      {/* <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                        onChange={(e) => handleSelectAllCheckbox(e, item?.id)} // Optional handler for checkbox
                      /> */}
                      <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600 border-gray-300 rounded"
                  onChange={(e) => handleRowCheckboxChange(e, item.id)}
                  checked={isRowSelected(item.id)}
                  aria-label={`Select row for ${item.title}`}
                />
                    </td>
                    <td className="mt-12 p-2">{item?.title}</td>
                    <td>{authData?.userDetails?.firstName}</td>
                    <td>{item?.assigneeName}</td>
                    <td>
                      {item?.active === true ? (
                        <span className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">
                          Active
                        </span>
                      ) : (
                        <span className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">
                          No active
                        </span>
                      )}
                    </td>
                    <td>{item?.nextDue}</td>
                    <td className="flex items-center justify-end mt-4">
                      <button
                        onClick={() => Pause(item?.id)}
                        className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-purple-400 border border-purple-400"
                      >
                        Pause
                      </button>
                      <button
                        onClick={() => Resume(item?.id)}
                        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
                      >
                        Resume
                      </button>
                      <button
                        onClick={() => hanldleDelete(item?.id)}
                        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center text-gray-600">
                      <div className="flex justify-center items-center">
                      <svg viewBox="0 0 138 138" fill="none" width="150" className="mt-4" height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>
                      </div>
                      <p className="text-gray-600 mt-4 text-lg font-medium">
                        No results
                      </p>
                      <p className="text-gray-500 text-sm mt-2 mb-10">
                      Try again with different search terms or filters
                      </p>
                    </td>
                  </tr>
                )
              ) : selectedTemplate?.length > 0 ? (
                selectedTemplate?.map((item, index) => (
                  <tr key={index} className="pt-12 p-12">
                    <td className="mt-12 p-2">{item?.title}</td>
                    <td>{authData?.userDetails?.firstName}</td>
                    <td>{item?.assigneeName}</td>
                    <td>
                      {item?.active === true ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-green-400 border border-green-400">
                          Active
                        </span>
                      ) : (
                        <span   className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">

                          No active
                        </span>
                      )}
                    </td>
                    <td>{item?.nextDue}</td>
                    <td className="flex items-center justify-end mt-4">
                      <button
                        onClick={() => Pause(item?.id)}
                        className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-purple-400 border border-purple-400"
                      >
                        Pause
                      </button>
                      <button
                        onClick={() => Resume(item?.id)}
                        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
                      >
                        Resume
                      </button>
                      <button
                        onClick={() => hanldleDelete(item?.id)}
                        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center text-gray-600">
                    <div className="flex justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 120 120"
                      >
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="57.266"
                          opacity=".35"
                          transform="rotate(-45.001 84.45 88.451)"
                        ></rect>
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="53.266"
                          fill="#0037ff"
                          transform="rotate(-45.001 84.45 84.451)"
                        ></rect>
                        <circle cx="49" cy="53" r="37" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="37" fill="#0075ff"></circle>
                        <circle cx="49" cy="53" r="28" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="28" fill="#a4e2f1"></circle>
                      </svg>
                    </div>
                    <p className="text-gray-600 mt-4 text-lg font-medium">
                      No results found
                    </p>
                    <p className="text-gray-500 text-sm mt-2">
                    Try again with different search terms or filters
                    </p>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={6} className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No scheduled inspections due in the next 7 days
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm md:text-base text-center">
                      You don't have any scheduled inspections starting in the
                      next 7 days. Learn more.
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {selectedRows.length > 0 && (
        <div className="bg-blue-500 p-4 mt-4 flex justify-between items-center border-t-4">
          <p className="text-sm text-white">
            {selectedRows.length} schedule selected
          </p>
          <div className="flex items-center space-x-4">
            <button
              className="text-blue-500 bg-white border border-white px-4 py-1 rounded flex items-center"
              onClick={toggleStatusDropdown}
            >
              <span className="mr-2">
                <svg
                  width="1.125rem"
                  height="1.125rem"
                  viewBox="0 0 24 24"
                  color="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.2872 9.70711C17.6777 9.31658 17.6777 8.68342 17.2872 8.29289C16.8967 7.90237 16.2635 7.90237 15.873 8.29289L10.5801 13.5858L8.28718 11.2929C7.89666 10.9024 7.2635 10.9024 6.87297 11.2929C6.48245 11.6834 6.48245 12.3166 6.87297 12.7071L9.87297 15.7071C10.2635 16.0976 10.8967 16.0976 11.2872 15.7071L17.2872 9.70711Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0801 1C6.00495 1 1.08008 5.92487 1.08008 12C1.08008 18.0751 6.00495 23 12.0801 23C18.1552 23 23.0801 18.0751 23.0801 12C23.0801 5.92487 18.1552 1 12.0801 1ZM3.08008 12C3.08008 7.02944 7.10952 3 12.0801 3C17.0506 3 21.0801 7.02944 21.0801 12C21.0801 16.9706 17.0506 21 12.0801 21C7.10952 21 3.08008 16.9706 3.08008 12Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              Change Status
            </button>
            <button
              className="text-blue-500 bg-white border border-white px-4 py-1 rounded flex items-center"
              onClick={openDeleteConfirmationPopup}
            >
              <span className="mr-2">
                <svg
                  width="1.125rem"
                  height="1.125rem"
                  viewBox="0 0 14 14"
                  focusable="false"
                >
                  <path
                    d="M3.541 11.083c.002.644.561 1.165 1.25 1.167h5c.69-.002 1.249-.523 1.25-1.167v-7H3.543v7zm8.125-8.75H9.479l-.625-.583H5.73l-.625.583H2.917V3.5h8.75l-.001-1.167z"
                    fill="#6559ff"
                    fill-rule="nonzero"
                  ></path>
                </svg>
              </span>
              Delete
            </button>

            {/* Cross icon to clear selection */}
            <div
              data-anchor="cancel-btn"
              role="button"
              className="multi-select-action-bar-styled__CancelContainerDiv-iauditor__sc-1o50t6o-6 cdEKrE"
              onClick={clearSelection}
            >
              <svg
                fill="#ffffff"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                focusable="false"
              >
                <path opacity=".87" fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"></path>
              </svg>
            </div>
          </div>
        </div>
      )}

      {/* Status dropdown for Change Status */}
      {isStatusDropdownOpen && (
        <div
          ref={statusDropdownRef}
          className="absolute mt-1 w-64 right-1 bg-white shadow-lg rounded-md p-4 z-10"
        >
          <ul>
            <li onClick={() => handleStatusChange("Pause")}>
              Pause
            </li>
            <li onClick={() => handleStatusChange("Resume")}>
              Resume
            </li>
          </ul>
        </div>
      )}

      {/* Delete confirmation popup */}
      {isConfirmDeletePopupOpen && (
          <div className="popup-overlay">
            <div className="popup">
              <p className="popup-message">Are you sure you want to delete?</p>
              <div className="popup-actions">
                <button onClick={confirmDeleteAction} className="popup-button confirm">
                  Yes
                </button>
                <button onClick={cancelDeleteAction} className="popup-button cancel">
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Manage;
