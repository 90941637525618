import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";
import { useNotification } from "../../../common/hooks/useNotification";
const CategoriePage = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [ setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null);
  const [ setCategoryText] = useState('');
  const [siteFields, setSiteFields] = useState([]);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  // const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false); // State for category popup visibility
  const [activeButton, setActiveButton] = useState("Issues");
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [name, setCreateTypeName] = useState("");
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const dropdownRef = useRef(null);
  const popupRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const confirmDelete = (index) => {
    setDeleteConfirmationIndex(index);
  };

  const handleDelete = () => {
    console.log("Deleted!"); // Replace with actual delete logic
    setDeleteConfirmationIndex(null);
    setDropdownVisible(null);
  };

  const closePopup = () => {
    setDeleteConfirmationIndex(null);
  };

  const handleClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };

  const handleCategoryClick = (index) => {
    setDropdownOpenIndex(dropdownOpenIndex === index ? null : index);
  };
  const handleEditClick = () => {
    navigate("/dashboard/edit-category");  // Redirect to a new page ("/edit-category" is an example)
  };

  const handleInputChange = (event) => {
    setCategoryText(event.target.value); // Update the input value
  };

  const handleSiteToggle = (index) => {
    const updatedFields = [...siteFields];
    updatedFields[index].isOn = !updatedFields[index].isOn; // Toggle the "On/Off" state for this specific site field
    setSiteFields(updatedFields);
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  // const toggleDropdown = (index) => {
  //   setDropdownVisible(dropdownVisible === index ? null : index);
  // };

  const handleAddSite = () => {
    setSiteFields([
      ...siteFields,
      { siteName: "", isOn: false, showFields: false } // Add a new site field with "off" state and hide fields initially
    ]);
  };

  const handleSiteNameChange = (index, event) => {
    const updatedFields = [...siteFields];
    updatedFields[index].siteName = event.target.value; // Update the site name for this specific site field
    setSiteFields(updatedFields);
  };


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };

  const toggleCategoryPopup = () => {
    setIsCategoryPopupOpen(!isCategoryPopupOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenIndex(null);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getCategory = async () => {
    try {
      const response = await sendRequest({
        url: "v1/categories/list?page=0&size=10&sortBy=name&sortDirection=ASC",
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
      });
      console.log(response, "Fetched category data");
      if (response && response.data) {
        setCategories(response.data.categories);
      } else {
        console.warn("No category data found in response");
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  // const filteredCategories = categories;

  // Filter categories based on searchTerm
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  useEffect(() => {
    getCategory();
  }, []);

  const handleSaveAssetType = async () => {
    try {
      await sendRequest(
        {
          url: "v1/categories/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            name: name,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setCreateTypeName("");
              setSearchTerm("");
              getCategory();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleChange = (e) => {
    // Regular expression to match only letters (a-z, A-Z) and spaces
    const regex = /^[A-Za-z\s]*$/;

    const value = e.target.value;

    if (regex.test(value)) {
      setCreateTypeName(value); // Update state if the value matches the regex
    } else {
      console.log("Special characters are not allowed!");
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div
        className={`${isSidebarOpen ? "w-2/6" : "w-0"} bg-white shadow-md fixed top-0 right-0 h-full transition-all ease-in-out duration-300 overflow-hidden z-50`}
      >
        <div className="h-20 bg-indigo-50">
          <h2 className="text-2xl font-semibold text-gray-700 p-4 text-left">
            Create issue QR code
          </h2>
        </div>
        <div className="p-6"> 
          <h2 className="text-sm text-gray-700 mb-2 text-left">QR code name (required)</h2>
          <input
            type="text"
            placeholder="Add name"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          />
          <div className="relative w-92">
            <input
              type="text"
              placeholder="Enter text"
              className="w-full pl-3 pr-12 py-2 border rounded-md"
            />
            <button
              onClick={() => handleCategoryClick(0)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-12 h-6 rounded-full bg-gray-400"
            >
              <div
                className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform`}
              ></div>
            </button>
            {dropdownOpenIndex === 0 && (
              <div className="absolute z-10 w-full mt-2 bg-white shadow-md rounded-md border">
                <div className="px-4 py-2">
                  {/* Search Input Field */}
                  <input
                    type="text"
                    placeholder="Enter text"
                    className="w-full pl-3 pr-12 py-2 border rounded-md"
                  />
                </div>
                <ul className="py-2">
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 1</li>
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 2</li>
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 3</li>
                </ul>
              </div>
            )}
          </div>

          {/* Add Site Button */}
          <button
            onClick={handleAddSite}
            className="text-sm text-indigo-600 hover:underline mb-4"
          >
            + Add Site
          </button>

          {/* Render Site Fields */}
          {siteFields.map((siteField, index) => (
            <div key={index} className="mb-4">
              <input
                type="text"
                value={siteField.siteName}
                onChange={(event) => handleSiteNameChange(index, event)}
                placeholder="Enter site name"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
              />
              <button
                onClick={() => handleSiteToggle(index)}
                className={`w-12 h-6 rounded-full ${siteField.isOn ? 'bg-green-500' : 'bg-gray-400'}`}
              >
                <div
                  className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform ${siteField.isOn ? 'transform translate-x-6' : ''}`}
                ></div>
              </button>

              {/* Show site fields if toggle is ON */}
              {siteField.isOn && (
                <div className="mt-4">
                  <div
                    className="relative"
                    onClick={() => handleCategoryClick(index + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="w-full pl-3 pr-12 py-2 border rounded-md text-gray-700">
                      Select site category
                    </div>
                    {dropdownOpenIndex === index + 1 && (
                      <div className="absolute z-10 w-full mt-2 bg-white shadow-md rounded-md border">
                        <div className="px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter category"
                            className="w-full pl-3 pr-12 py-2 border rounded-md"
                          />
                        </div>
                        <ul className="py-2">
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 1</li>
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 2</li>
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 3</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="flex justify-end mt-4 space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={toggleSidebar} // Add logic here to handle cancel action
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-indigo-200 text-white rounded hover:bg-indigo-600"
              onClick={() => {
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-0 transition-all duration-300 ease-in-out">
        {/* Header */}
        <div className="w-full px-4 border-b mt-4">
          <div className="w-full px-4 border-b">
          <div className="flex items-center justify-start space-x-8">
              <button
                className={`mb-4 ${
                  activeButton === "Issues"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("Issues", "/dashboard/issue-page")}
              >
                Issues
              </button>
              <button
                className={`mb-4 ${
                  activeButton === "Categories"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("Categories", "/dashboard/category-page")}
              >
                Categories
              </button>
              <button
                className={`mb-4 ${
                  activeButton === "QR codes"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("QR codes", "/dashboard/qr-page")}
              >
                QR codes
              </button>
            </div>
          </div>
        </div>
        
        {/* QR Code Header */}
        <div className="flex items-center justify-between w-full max-w-7xl px-4 mt-4">
          <div className="text-3xl font-medium text-gray-900 ml-4">Manage issue categories</div>
          <button
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 mr-10"
            onClick={toggleCategoryPopup} // Open Add Category Popup
          >
            + Add category
          </button>
        </div>

        {/* Add Category Popup */}
        {isCategoryPopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-2/6">
          <div className="flex justify-between">
          <h2 className="text-2xl mb-4 text-left">Add new category</h2>
          <button
            onClick={toggleCategoryPopup}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" fill="#545f70" focusable="false" data-anchor="cross-svg"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
            </button>
          </div>
            <div className="mb-4">
              <label
                htmlFor="categoryName"
                className="block text-sm text-gray-700 mb-1 text-left"
              >
                Name
              </label>
              <input
                type="text"
                id="categoryName"
                value={name}
                onChange={handleChange}
                placeholder="Enter category name"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={130}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-white text-indigo-700 border border-gray-300 rounded-lg hover:bg-indigo-50"
                onClick={toggleCategoryPopup}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded ${
                  name.trim()
                    ? "bg-indigo-500 text-white hover:bg-indigo-600"
                    : "bg-[#b1abff] text-white cursor-not-allowed"
                }`}
                onClick={() => {
                  handleSaveAssetType();
                  toggleCategoryPopup();
                }}
                disabled={!name.trim()} // Disable when input is empty
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      )}

        {/* Search and Filters */}
        <div className="w-full max-w-7xl px-4 mt-6">
          <div className="flex flex-col sm:flex-row justify-start items-center p-4 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="flex items-center justify-between w-full">
              <div className="relative flex items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="flex-1 sm:w-64 px-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <svg
                  viewBox="0 0 267 267"
                  width="18"
                  height="18"
                  focusable="false"
                  className="absolute left-3 text-gray-500"
                >
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h267v267H0z"></path>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#a)">
                    <path
                      fill="currentColor"
                      d="M172.438 155.75h-8.789l-3.115-3.004A71.993 71.993 0 0 0 178 105.688c0-39.938-32.375-72.313-72.312-72.313-39.938 0-72.313 32.375-72.313 72.313 0 39.937 32.375 72.312 72.312 72.312 17.912 0 34.377-6.564 47.059-17.466l3.004 3.115v8.789l55.625 55.513 16.576-16.576-55.513-55.625zm-66.75 0c-27.702 0-50.063-22.361-50.063-50.062 0-27.702 22.361-50.063 50.063-50.063 27.701 0 50.062 22.361 50.062 50.063 0 27.701-22.361 50.062-50.062 50.062z"
                    ></path>
                    <path d="M0 0h267v267H0V0z" fill="none"></path>
                  </g>
                </svg>
              </div>
              <p className="mr-10 text-gray-600">{filteredCategories.length} results</p>
              </div>
          </div>
        </div>
        {/* Table */}
        <div className="w-full max-w-6xl mt-6 bg-white rounded-md shadow-sm ml-7">
            <table className="w-full table-auto text-left border border-gray">
              <thead>
                <tr className="bg-indigo-50 text-gray-700 border-b border-gray-300">
                <th className="p-2 text-sm underline flex items-center font-normal">
                  <span>Category name</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="48"
                    viewBox="0 0 24 48"
                    className="cursor-pointer"
                  >
                    <text x="0" y="25" fontSize="20" fill="currentColor">↓</text>
                    <text x="0" y="40" fontSize="0" fill="currentColor">↓</text>
                  </svg>

                </th>
                <th className="p-2 text-sm font-normal">Category access</th>
                <th className="p-2 text-sm font-normal">Notify</th>
                <th className="p-2 text-sm text-center fon"></th>
                <th className="p-2 text-sm text-center fon"></th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories.length > 0 ? (
                  filteredCategories.map((type, index) => (
                    <tr
                      key={index}
                      className="border-b"
                      onClick={() => {
                        navigate(
                          `/dashboard/edit-category/${type.id}?name=${encodeURIComponent(
                            type.name
                          )}&id=${type.id}`
                        );
                      }}
                    >
                      <td className="p-4 text-sm text-gray-700">
                        <button>
                        {type.name.length > 30 ? `${type.name.slice(0, 30)}...` : type.name}
                        </button>
                      </td>
                      <td className="p-4 text-sm text-gray-700">
                        <button>
                        {type.isEnabled ? "All users" : "Disabled"}
                        </button>
                      </td>
                      <td className="p-4 text-sm text-gray-700">
                        <button>
                        {type.isVisibleToAll ? "None" : "Yes"}
                        </button>
                      </td>
                      <td className="p-4 text-sm text-center flex justify-end">
                        {/* Edit Button */}
                        <button
                          className="text-indigo-600 hover hover:bg-indigo-50 p-4 rounded"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleEditClick(type.id);
                          }}
                        >
                          Edit Category
                        </button>
                      </td>
                      <td>
                      <div className="inline-block relative flex justify-end">
                          <button
                            className="text-gray-600 focus:outline-none"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              toggleDropdown(index);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              className="cursor-pointer"
                            >
                              <circle cx="12" cy="5" r="2" fill="currentColor" />
                              <circle cx="12" cy="12" r="2" fill="currentColor" />
                              <circle cx="12" cy="19" r="2" fill="currentColor" />
                            </svg>
                          </button>
                          {dropdownVisible === index && (
                            <div className="absolute right-0 mt-2 bg-white border rounded shadow-lg w-32">
                              <ul>
                                <li
                                  className="p-2 hover:bg-indigo-100 text-sm text-gray-700 flex items-center gap-2 whitespace-nowrap"
                                  onClick={(e) => e.stopPropagation()} // Prevent row click
                                >
                                  <button onClick={() => confirmDelete(index)}>
                                    <svg width="18" height="18" viewBox="0 0 14 14" focusable="false">
                                      <path
                                        d="M3.541 11.083c.002.644.561 1.165 1.25 1.167h5c.69-.002 1.249-.523 1.25-1.167v-7H3.543v7zm8.125-8.75H9.479l-.625-.583H5.73l-.625.583H2.917V3.5h8.75l-.001-1.167z"
                                        fill="#1f2533"
                                        fillRule="nonzero"
                                      ></path>
                                    </svg>
                                    Delete Category
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                          {deleteConfirmationIndex === index && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
                            onClick={(e) => e.stopPropagation()}
                            >
                              <div className="bg-white p-4 rounded shadow-lg">
                                <p className="font-normal text-2xl ">Delete issue category</p>
                                <p className="text-gray-800">You're about to permanently delete this category. Issues previously reported for this category will <br /> remain available, but your team can't report issues for this category or change access to those <br/> issues once you delete the category.</p>
                                <div className="flex justify-end gap-2 mt-4">
                                  <button onClick={closePopup} className="px-3 py-1 bg-white border border-gray-400 rounded text-indigo-600 hover:bg-indigo-50">Cancel</button>
                                  <button onClick={handleDelete} className="px-3 py-2 bg-red-700 text-white rounded hover:bg-red-800">Delete Category</button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                  <td colSpan="5" className="h-64 text-center">
                    <div className="flex flex-col items-center justify-center text-center mt-20">
                      <svg
                        viewBox="0 0 138 138"
                        fill="none"
                        width="150"
                        height="150"
                        focusable="false"
                      >
                        <path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path>
                        <path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path>
                        <path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path>
                        <path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path>
                        <path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path>
                        <path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path>
                      </svg>
                      <p className="text-gray-600 mt-4 text-lg font-medium">No issues found</p>
                      <p className="text-gray-500 text-sm mt-2 mb-10">
                        Try again with different search terms or filters
                      </p>
                    </div>
                  </td>
                </tr>
                )}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default CategoriePage;
