import { useState, useEffect, useRef } from "react";
import { FiArrowLeft, FiDownload } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import GroupTab from "./groupMatrixTabs/group";
import AreaTab from "./groupMatrixTabs/area";
import CountryTab from "./groupMatrixTabs/country";
import RegionTab from "./groupMatrixTabs/region";
import SiteTab from "./groupMatrixTabs/site";
import StateTab from "./groupMatrixTabs/state";
import CreateGroupModal from "./createGroupModal";
import DownloadCSVModal from "./downloadCSVModal";
import { useNotification } from "../../../common/hooks/useNotification";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";
import { useApi } from "../../context/OrganizationContaxt";

const GroupMatrix = () => {
  const [groups, setGroups] = useState([]);
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const { authData } = useAuth();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Site");
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [downloadCSVOpen, setDownloadCSVOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [userNames, setUserNames] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const navigate = useNavigate();
  const { getSiteLists } = useApi();
  const [searchTerm, setSearchTerm] = useState("");
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [siteSearchTerm, setSiteSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("Active");

  const filteredSiteOptions = getSiteLists.filter((option) =>
    option?.siteName?.toLowerCase().includes(siteSearchTerm.toLowerCase())
  );

const handleFilterSelect = (filter) => {
  setSelectedFilter(filter);
  setIsGroupDropdownOpen(true); // Dropdown open karo

  // Filter ke mutabiq options set karna
  switch (filter) {
    case "Groups":
      setFilteredOptions(groups.map((group) => group.groupName));
      break;
      case "Users":
    setFilteredOptions([
      authData.userName
        ? (
            authData.userName.charAt(0) + authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()
        : "",
    ]);
    break;
      
    case "User status":
    setFilteredOptions(["Active", "Deactivated"]);
    setSelectedOption((prev) => (prev === "Active" ? "Deactivated" : "Active"));
    break;

    case "Seats type":
      setFilteredOptions(["Full Seat", "Lite Seat", "Guest Seat"]);
      break;
      case "Site members":
    setFilteredOptions(
      filteredSiteOptions.length > 0 
        ? filteredSiteOptions.map((site) => site.siteName) 
        : ["No sites"]
    );
    break;  
      case "Group members":
        setFilteredOptions(groups.map((group) => group.groupName));
        break;
      case "No membership":
        setFilteredOptions(["No Site Membership","No group Membership"]);
        break;
      default:
        setFilteredOptions([]);
        break;
    }
  };

  const handleToggleGroupDropdown = () => {
    setIsGroupDropdownOpen((prev) => !prev);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGroupSelect = (groupName) => {
    setSelectedGroups((prev) =>
      prev.includes(groupName)
        ? prev.filter((name) => name !== groupName) // Remove if already selected
        : [...prev, groupName] // Add if not selected
    );
  };


  const handleBackClick = () => {
    navigate(-1);
  };

  const groupCount = groups.length;
  const tabs = [
    `Group(${groupCount})`,
    `Country(0)`,
    `State(0)`,
    `Region(0)`,
    `Area(0)`,
    `Site(0)`,
  ];
  const renderContent = () => {
    switch (activeTab) {
      case "Group":
        return <GroupTab />;
      case "Country":
        return <CountryTab />;
      case "Devices":
        return <StateTab />;
      case "Region":
        return <RegionTab />;
      case "Area":
        return <AreaTab />;
      case "Site":
        return <SiteTab />;
      default:
        return null;
    }
  };
  

  const toggleFilterDropdown = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

   useEffect(() => {
      fetchGroups();
    }, [authData]);

  const fetchGroups = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/groups",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGroups(response.data.organizationGroups);
        }
      );
    } catch (error) {
      console.error("Error getting groups:", error);
    }
  };

  const handleGroupClick = (groupName) => {
    setSelectedGroups((prev) =>
      prev.includes(groupName) ? prev : [...prev, groupName]
    );
  };

  // Function to remove a selected group
  const removeSelectedGroup = (groupName) => {
    setSelectedGroups((prev) => prev.filter((name) => name !== groupName));
  };

  return (
    <div className="container mx-auto p-4">
      {createGroupOpen && (
        <CreateGroupModal
          setCreateGroupOpen={setCreateGroupOpen}
          onGroupCreated={fetchGroups} // Pass callback to update groups
        />
      )}
      {downloadCSVOpen && (
        <DownloadCSVModal setDownloadCSVOpen={setDownloadCSVOpen} />
      )}

      {/* Top bar */}
      <button onClick={handleBackClick} className="flex items-center text-gray-500 pt-3">
        <FiArrowLeft className="mr-2" />
        <span>Back</span>
      </button>

      <div className="flex justify-between items-center mb-6 mt-5">
        {/* Search and filter */}
        <div className="flex items-center space-x-4">
          <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search users"
              className="ml-2 py-1 outline-none bg-transparent"
            />
          </div>
          <div className="flex items-center">
            <div className="flex items-center space-x-2">
              {selectedGroups.map((groupName) => (
                <button
                  key={groupName}
                  className="bg-white border border-gray-300 text-indigo-600 px-4 py-2 rounded-lg flex items-center"
                >
                  <span className="text-indigo-600 mr-1">Sorted by:</span> {groupName}
                  <span
                    className="ml-2 text-purple-500 cursor-pointer"
                    onClick={() => removeSelectedGroup(groupName)}
                  >
                    ×
                  </span>
                </button>
              ))}
            </div>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover:text-blue-600"
              >
                + Add filter
              </button>

              {isFilterOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-gray-700 text-left">
                    {[
                      "Groups",
                      "Users",
                      "User status",
                      "Seats type",
                      "Site members",
                      "Group members",
                      "No membership",
                    ].map((filter) => (
                      <li
                        key={filter}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleFilterSelect(filter)}
                      >
                        {filter}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Dropdown Content for Selected Filter */}
              {isGroupDropdownOpen && (
                <div className="absolute mt-2 w-80 bg-white shadow-lg rounded-md p-3">
                  <div className="mt-2 text-gray-700 text-sm">
                    <div className="flex justify-between font-semibold">
                      <span>{selectedFilter}</span>
                    </div>

                    <div className="mt-1 max-h-40 overflow-y-auto">
                      {filteredOptions.map((option) => (
                        <label
                          key={option}
                          className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
                        >
                          <input type="checkbox" />
                          <span>{option}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div className="mt-3 flex justify-end">
                    <button
                      onClick={() => setIsGroupDropdownOpen(false)}
                      className="bg-indigo-600 text-white px-4 py-2 rounded-md"
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Create group and download buttons */}
        <div className="flex items-center space-x-4 mr-10">
          <button
            className="bg-[#6559ff] text-white px-4 py-2.5 rounded-lg"
            onClick={() => setCreateGroupOpen(true)}
          >
            + Create group
          </button>
          <button
            className="p-3 rounded-md border border-gray-300"
            onClick={() => setDownloadCSVOpen(true)}
          >
            <FiDownload />
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6 mr-10">
        <nav className="flex justify-between items-center border-b border-gray-300">
          <div className="flex space-x-7">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab ? "border-b-2 border-blue-600 text-blue-600" : ""
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="text-gray-600">Users: 1</div>
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>

      {activeTab.startsWith("Group") && (
        <div className="border border-gray-300 rounded-lg shadow-md h-full mr-10 p-4 bg-white">
          <table className="w-full mt-2 border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border p-2 text-left">Group Name</th>
              </tr>
            </thead>
            <tbody>
            {groups.length > 0 ? (
              groups.map((group, index) => (
                <tr
            key={index}
            className={`border cursor-pointer ${
              selectedOption === "Deactivated" ? "bg-gray-300 cursor-not-allowed" : "hover:bg-gray-50"
            }`}
            onClick={() => selectedOption === "Active" && handleGroupClick(group.groupName)}
          >
            <td className="border p-2">{group.groupName}</td>
          </tr>
              ))
            ) : (
              <tr>
                <td className="p-2 text-center text-gray-500">No groups available</td>
              </tr>
            )}
          </tbody>
          </table>
          <table className="w-full border-collapse mb-6">
            <tbody>
              <tr className="hover:bg-gray-50">
                <td className="py-4 px-6 flex items-center space-x-4">
                  <div className="h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-bold">
                    {(
                      authData.userName.charAt(0) +
                      authData.userName.charAt(authData.userName.length - 1)
                    ).toUpperCase()}
                  </div>
                  <div>
                    <p className="text-gray-900 font-medium">{authData.userName}</p>
                    <p className="text-sm text-gray-500">{authData.userName}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "Country(0)" && (
        <div className="border border-black h-full mr-10">
          <div className="font-medium text-gray-700">No Country level sites</div>
          <div className="text-medium text-gray-500 mb-4">
            Your organization does not have any sites at this level
          </div>
        </div>
      )}
      
      {activeTab === "State(0)" && (
        <div className="border border-black h-full mr-10">
          <div className="font-medium text-gray-700">No State level sites</div>
          <div className="text-medium text-gray-500 mb-4">
          Your organization does not have any sites at this level
          </div>
        </div>
      )}

      {activeTab === "Region(0)" && (
        <div className="border border-black h-full mr-10">
          <div className="font-medium text-gray-700">No Region level sites</div>
          <div className="text-medium text-gray-500 mb-4">
          Your organization does not have any sites at this level
          </div>
        </div>
      )}

      {activeTab === "Area(0)" && (
        <div className="border border-black h-full mr-10">
          <div className="font-medium text-gray-700">No Area level sites</div>
          <div className="text-medium text-gray-500 mb-4">
          Your organization does not have any sites at this level
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupMatrix;
