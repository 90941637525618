import React, { useEffect, useRef, useState } from "react";
import { FaPencilAlt, FaPlus, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiArrowDropDownLine, RiArrowGoBackFill } from "react-icons/ri";
import { MdDelete, MdDragIndicator } from "react-icons/md";
// import { TbLogicXnor } from "react-icons/tb";
import useHttp from "../../../common/hooks/useHttp";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNotification } from "../../../common/hooks/useNotification";
import { useAuth } from "../../../components/context/AuthContext";
import { TbSection } from "react-icons/tb";
import { useApi } from "../../../components/context/OrganizationContaxt";
import { Link, Navigate } from "react-router-dom";
import { TfiBackRight } from "react-icons/tfi";
function TemplatePage() {
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openDropdownsForTitle, setDropForTitle] = useState(null);
  // const [showDropdownTitlePage, setDropdownTitlePage] = useState(null);
  // const [ResponseSetTitle, setResponseSetTitle] = useState();
  const [openSetResponses, setOpenSetResponses] = useState({});
  const [openEditSetResponse, setOpenEditResponse] = useState({});
  const navigate = useNavigate();
  const isDisabled = true; // Replace with your condition
  const inputRef = useRef(null); // Create a ref for the input
  const [isEditable, setIsEditable] = useState(false); // State to control the editability

  const handleIconClick = () => {
    setIsEditable(true); // Enable editing
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when the icon is clicked
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setDropForTitle(null); // Close dropdown
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const Publish = () => {
    if (!isDisabled) {
      console.log("Publish button clicked");
      // Add your publish logic here
    }
  };
  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const goForward = () => {
    navigate(1); // Navigate to the next page
  };

  const [QuestionId, setSetQuestionId] = useState(null);
  const [responseSetData, setResponseSet] = useState({
    name: "",
    type: "SAFETY_STATUS",
    isGlobal: true,
    options: [],
  });
  const [responseSet, setResponsetResponse] = useState();
  // const [updateTitleReponsSet, setUpdatTitleResponSet] = useState();
  // const [updateGlobal, setUpdatGlobal] = useState();
  // const [updatType, setupdateType] = useState();
  const [responSetUpdateState, setResponSetUpdateState] = useState({
    name: "",
    isGlobal: "",
    type: "",
    options: [],
    id: "",
  });
  useEffect(() => {
    // Only update state if the responseSet has changed
    if (responseSet) {
      setResponSetUpdateState({
        name: responseSet?.name || "",
        isGlobal: responseSet?.global || "",
        type: responseSet?.type || "",
        options: responseSet?.options || [],
        id: responseSet?.id,
      });
    }
  }, [responseSet]);

  const { ResponseLists, getResponseLists, getTamplate } = useApi();

  const notify = useNotification();
  const { authData } = useAuth();
  const { sendRequest } = useHttp();

  const [data, setData] = useState({
    title: "",
    description: "",
    imageUrl: "",
    titlePageFields: [
      {
        fieldName: "Test conducted",
        fieldType: "TITLE_PAGE_FIELD",
        orderIndex: 0,
        required: true,
        responseType: "TEXT",
        responseSetId: null,
        responseConfig: {
          minCharacters: 5,
          maxCharacters: 100,
          multiline: false,
        },
        multipleSelection: false,
        flaggedResponses: false,
      },
      {
        fieldName: "Conducted on",
        fieldType: "TITLE_PAGE_FIELD",
        orderIndex: 1,
        required: true,
        responseType: "DATE_TIME",
        responseSetId: null,
        responseConfig: {
          includeTime: true,
          futureOnly: false,
          pastOnly: false,
        },
        multipleSelection: false,
        flaggedResponses: false,
      },
      {
        fieldName: "Location",
        fieldType: "TITLE_PAGE_FIELD",
        orderIndex: 2,
        required: true,
        responseType: "LOCATION",
        responseSetId: null,
        responseConfig: null,
        multipleSelection: false,
        flaggedResponses: false,
      },
    ],
    pages: [
      {
        title: "",
        orderIndex: 0,
        sections: [
          {
            title: "",
            orderIndex: 0,
            repeatable: false,
            questions: [
              {
                question: "",
                orderIndex: 0,
                required: false,
                responseType: "SITE",
                responseSetId: null,
                multipleSelection: false,
                flaggedResponses: false,
                logic: [
                  {
                    operator: "EQUALS",
                    value: "POOR",
                    requireAction: true,
                    requireEvidence: true,
                    askQuestions: false,
                    notify: true,
                    evidenceRequirement: {
                      requiresNotes: true,
                      requiresMedia: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Temporary preview URL
      const previewUrl = URL.createObjectURL(file);

      const imageUrl = `https://example.com/images/${file.name}`;

      setData((prevData) => ({
        ...prevData,
        imageUrl: imageUrl,
        previewImageUrl: previewUrl,
      }));
    }
  };

  const addPage = () => {
    const newPage = {
      id: data.pages.length + 1,
      title: "",
      orderIndex: data.pages.length,
      sections: [
        {
          id: 1,
          title: "",
          orderIndex: 0,
          repeatable: false,
          questions: [
            {
              id: 1,
              question: "",
              orderIndex: 0,
              required: false,
              responseType: "TEXT",
              responseSetId: null,
              multipleSelection: false,
              flaggedResponses: false,
              logic: [],
            },
          ],
        },
      ],
    };
    setData({ ...data, pages: [...data.pages, newPage] });
  };

  const addSection = (pageId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const newSection = {
      id: updatedPages[pageIndex].sections.length + 1,
      title: "",
      orderIndex: updatedPages[pageIndex].sections.length,
      repeatable: false,
      questions: [],
    };
    updatedPages[pageIndex].sections.push(newSection);
    setData({ ...data, pages: updatedPages });
  };
  const addRowToTitlePage = () => {
    const newField = {
      fieldName: `New Field ${data.titlePageFields.length + 1}`,
      fieldType: "TITLE_PAGE_FIELD",
      orderIndex: data.titlePageFields.length,
      required: false,
      responseType: responseType,
      responseSetId: null,
      responseConfig: {
        minCharacters: 0,
        maxCharacters: 100,
        multiline: false,
      },
      multipleSelection: false,
      flaggedResponses: false,
    };

    // Update the titlePageFields array with the new field
    const updateTitlePage = [...data.titlePageFields, newField];

    // Update the state with the modified array
    setData((prevData) => ({
      ...prevData,
      titlePageFields: updateTitlePage,
    }));
  };

  const addRowToSection = (pageId, sectionId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const sectionIndex = updatedPages[pageIndex].sections.findIndex(
      (section) => section.id === sectionId
    );
    updatedPages[pageIndex].sections[sectionIndex].questions.push({
      id: updatedPages[pageIndex].sections[sectionIndex].questions.length + 1,
      question: "",
      orderIndex:
        updatedPages[pageIndex].sections[sectionIndex].questions.length,
      required: false,
      responseType: "SITE",
      responseSetId: null,
      responseConfig: {
        minCharacters: 5,
        maxCharacters: 100,
        multiline: false,
      },
      multipleSelection: false,
      flaggedResponses: false,
      logic: [],
    });
    setData({ ...data, pages: updatedPages });
  };

  const deleteRow = (pageId, sectionId, rowId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const sectionIndex = updatedPages[pageIndex].sections.findIndex(
      (section) => section.id === sectionId
    );
    updatedPages[pageIndex].sections[sectionIndex].questions = updatedPages[
      pageIndex
    ].sections[sectionIndex].questions.filter((row) => row.id !== rowId);
    setData({ ...data, pages: updatedPages });
  };

  const deleteSection = (pageId, sectionId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    updatedPages[pageIndex].sections = updatedPages[pageIndex].sections.filter(
      (section) => section.id !== sectionId
    );
    setData({ ...data, pages: updatedPages });
  };

  const deletePage = (pageId) => {
    const updatedPages = data.pages.filter((page) => page.id !== pageId);
    setData({ ...data, pages: updatedPages });
  };

  const handleInputChange = (pageId, sectionId, rowId, fields) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);

    if (pageIndex === -1) {
      console.error(`Page with id ${pageId} not found.`);
      return;
    }

    // Update page title or other fields at the page level
    if (fields.pageTitle) {
      updatedPages[pageIndex].title = fields.pageTitle;
    }

    const sectionIndex =
      sectionId !== null
        ? updatedPages[pageIndex].sections.findIndex(
            (section) => section.id === sectionId
          )
        : null;

    if (sectionId !== null && sectionIndex === -1) {
      console.error(`Section with id ${sectionId} not found.`);
      return;
    }

    // Update section title or other fields at the section level
    if (sectionId !== null && fields.sectionTitle) {
      updatedPages[pageIndex].sections[sectionIndex].title =
        fields.sectionTitle;
    }

    // Update question field values at the question level
    if (rowId !== null && sectionId !== null) {
      const rowIndex = updatedPages[pageIndex].sections[
        sectionIndex
      ].questions.findIndex((row) => row.id === rowId);

      if (rowIndex === -1) {
        console.error(`Row with id ${rowId} not found.`);
        return;
      }

      // Loop over each field and update values accordingly
      Object.keys(fields).forEach((key) => {
        if (key !== "pageTitle" && key !== "sectionTitle") {
          updatedPages[pageIndex].sections[sectionIndex].questions[rowIndex][
            key
          ] = fields[key];
        }
      });
    } else if (sectionId !== null) {
      // If rowId is null, update section fields (like title, etc.)
      Object.keys(fields).forEach((key) => {
        if (key !== "pageTitle" && key !== "sectionTitle") {
          updatedPages[pageIndex].sections[sectionIndex][key] = fields[key];
        }
      });
    }

    setData({ ...data, pages: updatedPages });
    setOpenDropdowns({});
  };
  console.log("data", data);

  const SetResponseSet = async () => {
    try {
      await sendRequest(
        {
          url: "/v1/response-set/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: responseSetData,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getResponseLists();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const SetResponseSetUpdate = async (id) => {
    try {
      await sendRequest(
        {
          url: `/v1/response-set/update/${id}`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: responSetUpdateState,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getResponseLists();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const [isFocused, setIsFocused] = useState(null);
  const [show, setshow] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const { pageId, sectionId } = result.source.droppableId.split("-"); // Extract IDs from droppableId
    const pageIndex = data.pages.findIndex((page) => page.id === pageId);
    const sectionIndex = data.pages[pageIndex].sections.findIndex(
      (section) => section?.id === sectionId
    );

    if (pageIndex === -1 || sectionIndex === -1) {
      console.error("Page or Section not found");
      return;
    }

    // Copy questions array
    const questions = Array.from(
      data.pages[pageIndex].sections[sectionIndex].questions
    );

    // Perform the drag-and-drop operation
    const [removed] = questions.splice(sourceIndex, 1);
    questions.splice(destinationIndex, 0, removed);

    // Update state
    setData((prev) => {
      const updatedData = { ...prev };
      updatedData.pages[pageIndex].sections[sectionIndex].questions = questions;
      return updatedData;
    });
  };
  const [newOption, setNewOption] = useState({
    value: "",
    label: "",
    color: "#000000",
    flagged: false,
    score: null,
  });
  const handleAddResponse = () => {
    setResponseSet((prev) => ({
      ...prev,
      // name: prev?.name,
      // isGlobal: prev?.isGlobal,

      options: [
        ...prev.options,
        {
          value: "",
          label: "",
          color: "#000000",
          flagged: false,
          score: null,
        },
      ],
    }));
  };
  const handleAddResponseUpdate = () => {
    setResponSetUpdateState((prev) => ({
      ...prev,
      // name: prev?.name,
      // isGlobal: prev?.isGlobal,

      options: [
        ...prev.options,
        {
          value: "",
          label: "",
          color: "#000000",
          flagged: false,
          score: null,
        },
      ],
    }));
  };
  // const [responseSetId, setResponseId] = useState();
  // const [TitlePageresponseSetId, setTitlePageResponseId] = useState();
  // const [TitlteResponseName, setTitleResponseName] = useState();
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [QuestionfocusedIndex, setFocusedOfQuestion] = useState(null);

  const setFocus = (index) => {
    setFocusedOfQuestion(index);
  };

  const handleUpdateChange = (index, key, value) => {
    setResponSetUpdateState((prev) => {
      const updatedOptions = [...prev.options];
      updatedOptions[index][key] = value;
      return { ...prev, options: updatedOptions };
    });
  };
  const handleChange = (index, key, value) => {
    setResponseSet((prev) => {
      const updatedOptions = [...prev.options];
      updatedOptions[index][key] = value;
      return { ...prev, options: updatedOptions };
    });
  };
  const [scoring, setScoring] = useState(false);

  const [responseType, setResponseType] = useState("TEXT");
  const [responseTypePages, setResponseForPages] = useState("TEXT");
  const responseOptions = [
    {
      label: "Site",
      responseType: "SITE",
      icon: (
        <svg
          data-anchor="sell-black-svg"
          viewBox="0 0 24 24"
          width="15"
          height="15"
          fill="#648fff"
          focusable="false"
        >
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            d="M21.41 11.41l-8.83-8.83c-.37-.37-.88-.58-1.41-.58H4c-1.1 0-2 .9-2 2v7.17c0 .53.21 1.04.59 1.41l8.83 8.83c.78.78 2.05.78 2.83 0l7.17-7.17c.78-.78.78-2.04-.01-2.83zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5 8 5.67 8 6.5 7.33 8 6.5 8z"
            fill="#648fff"
          ></path>
        </svg>
      ),
    },
    {
      label: "Assets",
      responseType: "ASSET",
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 24 24"
          color="#5e9cff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4033 1.18456C12.1375 1.12989 11.8633 1.12989 11.5975 1.18456C11.2902 1.24775 11.0156 1.40158 10.7972 1.52387L10.7377 1.55712L3.3377 5.66823L3.27456 5.70311C3.04343 5.83041 2.75281 5.99049 2.52963 6.23266C2.33668 6.44204 2.19066 6.69021 2.10134 6.96056C1.99802 7.27326 1.99923 7.60504 2.00019 7.86891L2.00037 7.94104V16.0582L2.00019 16.1303C1.99923 16.3942 1.99802 16.7259 2.10134 17.0386C2.19066 17.309 2.33668 17.5572 2.52964 17.7665C2.7528 18.0087 3.0434 18.1688 3.27452 18.2961L3.3377 18.331L10.7377 22.4421L10.7972 22.4753C11.0155 22.5976 11.2902 22.7514 11.5975 22.8146C11.8633 22.8693 12.1375 22.8693 12.4033 22.8146C12.7106 22.7514 12.9852 22.5976 13.2035 22.4753L13.263 22.4421L20.663 18.331L20.7262 18.2961C20.9573 18.1688 21.2479 18.0087 21.4711 17.7665C21.6641 17.5572 21.8101 17.309 21.8994 17.0386C22.0027 16.7259 22.0015 16.3942 22.0005 16.1303L22.0004 16.0582V7.94104L22.0005 7.86891C22.0015 7.60504 22.0027 7.27326 21.8994 6.96056C21.8101 6.6902 21.6641 6.44204 21.4711 6.23266C21.2479 5.99048 20.9573 5.83041 20.7262 5.70311L20.663 5.66823L13.263 1.55712L13.2035 1.52387C12.9852 1.40158 12.7105 1.24775 12.4033 1.18456ZM11.709 3.30544C11.8605 3.22125 11.9379 3.17871 11.9956 3.15087L12.0004 3.14858L12.0051 3.15087C12.0629 3.17871 12.1402 3.22125 12.2918 3.30544L18.9408 6.99938L12.0002 10.8553L5.05971 6.99948L11.709 3.30544ZM4.00037 8.69888V16.0582C4.00037 16.2411 4.00078 16.3348 4.00487 16.4026L4.00523 16.4083L4.01007 16.4114C4.06737 16.4479 4.14903 16.4938 4.30898 16.5826L11 20.2999V12.5876L4.00037 8.69888ZM13 20.3003L19.6918 16.5826C19.8517 16.4938 19.9334 16.4479 19.9907 16.4114L19.9955 16.4083L19.9959 16.4026C20 16.3348 20.0004 16.2411 20.0004 16.0582V8.69867L13 12.5878V20.3003Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
    },
    {
      label: "Text Answer",
      responseType: "TEXT",
      icon: (
        <svg width="15" height="15" viewBox="0 0 14 14" focusable="false">
          <path
            d="M2.33333333,2.33333333 L2.33333333,4.97716191 L3.7929974,4.97716191 L3.7929974,4.28724799 C3.7929974,4.03503038 3.985625,3.82984264 4.22242188,3.82984264 L6.11229427,3.82984264 L6.11229427,9.52966171 C6.11229427,9.88941502 5.83754427,10.1820993 5.49979427,10.1820993 L4.8983776,10.1820993 L4.8983776,11.6666667 L9.11447396,11.6666667 L9.11447396,10.1820993 L8.51305729,10.1820993 C8.17534375,10.1820993 7.90055729,9.88941502 7.90055729,9.52966171 L7.90055729,3.82982322 L9.77757813,3.82982322 C10.0143568,3.82982322 10.2070026,4.03501096 10.2070026,4.28722858 L10.2070026,4.97714249 L11.6666667,4.97714249 L11.6666667,2.33333333 L2.33333333,2.33333333 Z"
            fill="#fe8500"
            fillRule="nonzero"
          ></path>
        </svg>
      ),
    },
    {
      label: "Checkbox",
      responseType: "CHECKBOX",
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="15"
          height="15"
          focusable="false"
          data-anchor="checked-checkbox-svg"
        >
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path
            fill="#5e9cff"
            d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29a.996.996 0 0 1-1.41 0L5.71 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.58 7.59z"
          ></path>
        </svg>
      ),
    },

    {
      label: "Number",
      responseType: "NUMBER",
      icon: (
        <svg width="15" height="15" viewBox="0 0 14 14" focusable="false">
          <g fill="#fe8500" fill-rule="nonzero">
            <path d="M2.21 9.682a.637.637 0 0 1-.637-.636V4.985l-.352.175a.636.636 0 1 1-.568-1.138l1.272-.636a.635.635 0 0 1 .921.57v5.09a.637.637 0 0 1-.636.636zM7.937 9.682H4.755a.637.637 0 0 1-.45-1.086l2.546-2.545a.85.85 0 0 0 .25-.605.849.849 0 0 0-.25-.604.874.874 0 0 0-1.21 0 .846.846 0 0 0-.25.604.637.637 0 0 1-1.272 0c0-.569.221-1.103.623-1.504.805-.804 2.205-.804 3.009 0 .402.402.623.937.623 1.504 0 .568-.222 1.103-.623 1.505L6.29 8.41h1.646a.637.637 0 0 1 0 1.272zM13 6.104c.214-.29.346-.646.346-1.035 0-.966-.785-1.75-1.75-1.75-.656 0-1.251.362-1.553.944a.636.636 0 1 0 1.13.586.477.477 0 1 1 .423.697.637.637 0 0 0 0 1.273.797.797 0 0 1 0 1.59.797.797 0 0 1-.795-.795.637.637 0 0 0-1.273 0 2.07 2.07 0 0 0 2.068 2.068 2.07 2.07 0 0 0 2.068-2.068c0-.597-.258-1.132-.665-1.51z"></path>
          </g>
        </svg>
      ),
    },
    {
      label: "Date & Time",
      responseType: "DATE_TIME",
      icon: (
        <svg viewBox="0 0 24 24" width="15" height="15" focusable="false">
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path
            fill="#81b532"
            d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"
          ></path>
        </svg>
      ),
    },
    {
      label: "Media",
      responseType: "MEDIA",
      icon: (
        <svg
          width="15"
          height="15"
          viewBox="0 0 16 16"
          focusable="false"
          fill="none"
        >
          <path
            d="M16 11.2V1.6c0-.88-.72-1.6-1.6-1.6H4.8c-.88 0-1.6.72-1.6 1.6v9.6c0 .88.72 1.6 1.6 1.6h9.6c.88 0 1.6-.72 1.6-1.6zM7.52 8.424l1.304 1.744 2.064-2.576a.4.4 0 0 1 .624 0l2.368 2.96a.399.399 0 0 1-.312.648H5.6a.4.4 0 0 1-.32-.64l1.6-2.136a.406.406 0 0 1 .64 0zM0 4v10.4c0 .88.72 1.6 1.6 1.6H12c.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8H2.4c-.44 0-.8-.36-.8-.8V4c0-.44-.36-.8-.8-.8-.44 0-.8.36-.8.8z"
            fill="#00b6cb"
          ></path>
        </svg>
      ),
    },
    {
      label: "Slider",
      responseType: "SLIDER",
      icon: (
        <svg viewBox="0 0 14 14" width="15" height="15" focusable="false">
          <g id="icon_slider_v2" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(1.5 1)" fill="#1ecf93">
              <g id="Group-3">
                <g id="Group-2">
                  <path
                    d="M1.75 2v2H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 2h1.25zm4 0h4.75a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5.75V2z"
                    id="Combined-Shape"
                  ></path>
                  <rect
                    id="Rectangle-Copy-2"
                    x="2.25"
                    y="0.5"
                    width="3"
                    height="5"
                    rx="0.5"
                  ></rect>
                </g>
              </g>
              <g id="Group-3-Copy" transform="matrix(-1 0 0 1 11 6)">
                <g id="Group-2">
                  <path
                    d="M1.75 2v2H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 2h1.25zm4 0h4.75a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5.75V2z"
                    id="Combined-Shape"
                  ></path>
                  <rect
                    id="Rectangle-Copy-2"
                    x="2.25"
                    y="0.5"
                    width="3"
                    height="5"
                    rx="0.5"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      label: "Annotation",
      responseType: "ANNOTATION",
      icon: (
        <svg viewBox="0 0 14 14" width="15" height="15" focusable="false">
          <g id="icon_annotate_2" fill="none" fill-rule="evenodd">
            <path
              d="M1.524 11.854c.141.239.753 1.21 1.345 1.143.151-.017.324-.165.546-.424.268-.315 1.098-.73 2.353-.827 1.072-.082 1.973-.866 2.537-2.206.156-.37.285-.768.383-1.183l.066-.263 4.13-3.605a.316.316 0 0 0 .028-.465l-2.854-2.933a.298.298 0 0 0-.452.029L6.122 5.376c-.55-.05-1.119.027-1.69.23a5.627 5.627 0 0 0-2.195 1.45c-.662.713-1.077 1.552-1.203 2.426-.114.8.06 1.642.49 2.372zm3.368-5.2c-.002-.518.744-.656 1.172-.611.024.002.276.025.33.046L7.873 7.67c-.01.055-.06.245-.067.276a6.957 6.957 0 0 1-.333 1.073c-.3.74-.854 1.598-1.773 1.712-1.561.192-.8-1.501-.808-4.077z"
              id="Shape"
              fill="#ffb000"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      label: "Signature",
      responseType: "SIGNATURE",
      icon: (
        <svg width="15" height="15" viewBox="0 0 14 14" focusable="false">
          <path
            d="M9.513 5.581l1.958.695-1.628 4.284c-.153.403-.98 1.663-1.555 1.92l-.14.368a.24.24 0 0 1-.306.138.229.229 0 0 1-.142-.297l.132-.348c-.292-.548-.074-2.14.054-2.476L9.513 5.58zm2.834-4.532c-.538-.19-1.169.203-1.35.679L9.819 4.832l1.958.694 1.178-3.104c.149-.389-.067-1.182-.607-1.373zM8.804 5.421a.478.478 0 0 0 .614-.272l1.245-3.243a.457.457 0 0 0-.282-.593.483.483 0 0 0-.615.272L8.522 4.828a.457.457 0 0 0 .282.593zM7.13 11.286c-.125-.117-.296-.5-.42-.35-.124.15-.035.094-.182.09h-.051c-.093-.251-.28-.41-.562-.471-.372-.078-.67.096-.875.23.018-.103.048-.225.07-.314.072-.284.145-.579.09-.855a.494.494 0 0 0-.452-.395c-.576-.032-1.047.276-1.461.554-.436.292-.715.466-.993.368-.34-.12-.374-1.031-.21-1.843.145-.731.417-2.093 1.113-2.71.234-.209.573-.434.852-.325.328.128.599.664.66 1.302.025.27.261.467.538.443a.491.491 0 0 0 .446-.535c-.098-1.04-.59-1.854-1.282-2.124-.415-.16-1.075-.203-1.875.507-.87.773-1.19 2.084-1.424 3.251-.116.583-.4 2.517.85 2.959.76.269 1.38-.147 1.876-.48.091-.06.181-.12.268-.174-.083.356-.134.737.083 1.058.322.482.779.534 1.356.157l.072-.047c.053.11.148.233.32.316.207.101.415.106.566.11.065.002.153.004.18.015.093.041-.228-.1-.121.001.08.075.165.153.272.234a.496.496 0 0 0 .692-.099.488.488 0 0 0-.1-.687c-.308-.19-.241-.134-.296-.186z"
            fill="#00b6cb"
            fill-rule="nonzero"
          ></path>
        </svg>
      ),
    },
    {
      label: "Location",
      responseType: "LOCATION",
      icon: (
        <svg width="15" height="15" viewBox="0 0 14 14">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M7,2 C5.065,2 3.5,3.60760144 3.5,5.59527478 C3.5,7.73703133 5.71,10.6902928 6.62,11.8151002 C6.82,12.0616333 7.185,12.0616333 7.385,11.8151002 C8.29,10.6902928 10.5,7.73703133 10.5,5.59527478 C10.5,3.60760144 8.935,2 7,2 Z M7,6.87930149 C6.31,6.87930149 5.75,6.30405752 5.75,5.59527478 C5.75,4.88649204 6.31,4.31124807 7,4.31124807 C7.69,4.31124807 8.25,4.88649204 8.25,5.59527478 C8.25,6.30405752 7.69,6.87930149 7,6.87930149 Z"
              fill="#fe8500"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      ),
    },
  ];
  const [TamplateId, setTamplateId] = useState();
  const deleteTitlePageField = (index) => {
    const updatedFields = [...data.titlePageFields];
    updatedFields.splice(index, 1);
    setData((prev) => ({
      ...prev,
      titlePageFields: updatedFields,
    }));
  };

  const createTemplate = async () => {
    try {
      await sendRequest(
        {
          url: "v1/template/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: data,
        },
        (response) => {
          // setTamplateId(response?.data?.templateId);
          if (response) {
            if (response.responseCode == "200") {
              getTamplate();
              setTamplateId(response?.data?.templateId);
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const getResponSetWith = async (id) => {
    try {
      const responseOfResponseSet = await sendRequest({
        url: `v1/response-set/${id}`,
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
      });

      return responseOfResponseSet;
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const getResponsetsWithID = async (id) => {
    try {
      const responSetbyId = await getResponSetWith(id);
      setResponsetResponse(responSetbyId?.data?.responseSet);
      setOpenEditResponse(false);
      console.log(responSetbyId, "responeSet");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      const fetchData = async () => {
        try {
          const response = await getResponSetWith(); // Pass a valid 'id' here
          if (response?.data?.responseSet) {
            setResponsetResponse(response.data.responseSet); // Set the data in state
          }
        } catch (err) {
          console.log(err.message); // Handle any error here
        }
      };

      fetchData(); // Call the async function
    }
  }, [authData]);
  const Pulish = async () => {
    const data = TamplateId;
    try {
      await sendRequest(
        {
          url: "v1/template/publish",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            templateId: data,
          },
        },
        (response) => {
          if (response) {
            Navigate("/dashboard/templates");
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  // const updateTitlePageField = (index, newValues) => {
  //   const updatedFields = [...data.titlePageFields];
  //   updatedFields[index] = {
  //     ...updatedFields[index],

  //     fieldName: newValues,
  //   };

  //   setData((prev) => ({
  //     ...prev,
  //     titlePageFields: updatedFields,
  //   }));
  // };
  const [selectedIndex, setSelectedIndex] = useState();
  const handleOptionClick = (index, responseType, responseTypeId) => {
    const updatedFields = [...data.titlePageFields];
    setSelectedIndex(index);
    updatedFields[index] = {
      ...updatedFields[index],
      responseType: responseType,
      responseSetId: responseTypeId,
    };

    setData((prevData) => ({
      ...prevData,
      titlePageFields: updatedFields,
    }));
    // console.log("updatedFields", updatedFields);
    setDropForTitle(null);
  };
  const handleInputTemplatPageInputeChange = (index, property, value) => {
    const updatedFields = [...data.titlePageFields];
    updatedFields[index] = {
      ...updatedFields[index],
      [property]: value,
    };

    setData((prevData) => ({
      ...prevData,
      titlePageFields: updatedFields,
    }));
    console.log(updatedFields, "ssdsdsd");
  };
  const inputRefs = useRef([]);
  const [focusedIndexPageQuestions, setFocusedIndexPagesQuestions] =
    useState(null);
  useEffect(() => {
    if (QuestionfocusedIndex !== null) {
      inputRefs.current[QuestionfocusedIndex]?.focus(); // Restore focus to the input
    }
  }, [QuestionfocusedIndex]);
  const renderTemplaTeTitleQuestions = () => {
    const toggleDropdownForTemplate = (index) => {
      setDropForTitle(index);
    };

    return (
      <div className="max-h-screen   bg-gray-100 p-6">
        <div className=" flex   items-start gap-2 rounded-lg">
          <div className="flex flex-col shadow-sm gap-3 bg-white p-2 ">
            <button className="flex text-xs rounded-md justify-center items-center flex-col">
              <FaPlus size={15} color="green" />
              Questions
            </button>
            <button className="flex text-xs justify-center items-center flex-col">
              <TbSection className=" text-indigo-600 " size={15} />
              Sections
            </button>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="w-full text-start border border-gray-200">
              {/* Header */}
              <div className="flex bg-gray-100">
                <div
                  className="border border-gray-300 px-4 py-2"
                  style={{ width: "80%" }}
                >
                  Questions
                </div>
                <div
                  onClick={toggleDropdownForTemplate}
                  className="border border-gray-300 px-4 py-2"
                  style={{ width: "40%" }}
                >
                  Type of response
                </div>
              </div>

              <Droppable droppableId="questions-list">
                {(provided) => (
                  <div
                    className="bg-white"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="mb-4">
                      {/* Section Heading */}
                      {/* <div className="border-r border-gray-300 px-4 py-2 flex-1 flex items-center gap-6">
                      </div> */}
                      {/* <MdDragIndicator
                          onClick={() => setIsFocused(false)}
                          size={30}
                          color="gray"
                          className="cursor-pointer"
                        /> */}

                      {/* <div class="flex items-center">
                          <input
                            id="link-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="link-checkbox"
                            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Repeatable
                          </label>
                        </div> */}
                      {/* Questions List */}
                      <div className="bg-white border border-gray-300 rounded-b-md">
                        {data?.titlePageFields?.map((field, index) => (
                          <Draggable
                            key={`${index}`}
                            draggableId={`${field?.fieldName}-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps} // Required for draggable
                                  {...provided.dragHandleProps} // Required for drag handle
                                  className="flex border-b border-gray-300 hover:bg-gray-50"
                                >
                                  {/* Field Name Column */}

                                  <div className="border-r border-gray-300 px-4 py-2 flex-1 flex items-center gap-6  ">
                                    <div className="relative group rounded-full hover:bg-gray-300 p-2">
                                      <MdDragIndicator
                                        size={30}
                                        color="gray"
                                        className="cursor-pointer"
                                      />

                                      {/* Text shown on hover */}
                                      <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white bg-black px-2 py-1 text-sm opacity-0 group-hover:opacity-100 group-hover:left-[-80px] transition-all duration-200">
                                        Drag
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      // onChange={(e) =>

                                      // }

                                      name="FieldName"
                                      defaultValue={field?.fieldName} // Controlled input field
                                      className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                                      placeholder="Field Name"
                                      onChange={(e) => {
                                        handleInputTemplatPageInputeChange(
                                          index,
                                          "fieldName",
                                          e.target.value
                                        );
                                      }}
                                      required
                                      onClick={() => setIsFocused(index)}
                                    />

                                    <button
                                      onClick={() =>
                                        deleteTitlePageField(index)
                                      }
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>

                                  {/* Field Type Column */}
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleDropdownForTemplate(index);
                                    }}
                                    className="flex items-center border-gray-300 px-4 py-2 cursor-pointer relative"
                                  >
                                    <span>
                                      {field?.responseType || " Response type "}
                                    </span>

                                    {openDropdownsForTitle === index && (
                                      <div className="bg-white absolute   z-10  right-0  p-3">
                                        <div className="flex items-start h-auto justify-between gap-6  bg-white rounded-lg p-4">
                                          <div className="w-full  overflow-y-scroll h-[20rem] text-start">
                                            <div className="flex items-center w-[20rem] border border-gray-300 rounded-lg px-3 py-2">
                                              <FaSearch className="text-gray-500" />
                                              <input
                                                type="text"
                                                id="last_name"
                                                className="outline-none text-gray-900 text-sm w-full px-4 py-1 bg-transparent"
                                                placeholder="Search"
                                                required
                                              />
                                            </div>

                                            {/* Multiple Choice Section */}
                                            <div className="border-b-2 pb-5">
                                              <div className="flex mt-4 items-center justify-around">
                                                <span className="text-gray-700 text-sm">
                                                  Multiple Choice
                                                </span>
                                                <button
                                                  onClick={() => {
                                                    setOpenSetResponses(false);
                                                  }}
                                                  className="text-blue-800 underline cursor-pointer flex gap-2 items-center"
                                                >
                                                  <FaPlus size={12} />
                                                  <span className="text-sm">
                                                    Response
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                            {ResponseLists?.data?.responseSets?.map(
                                              (responseSetData) => (
                                                <div className="flex mt-3 justify-around items-start">
                                                  <div
                                                    onClick={() =>
                                                      handleOptionClick(
                                                        index,
                                                        responseSetData?.type,
                                                        responseSetData?.id
                                                      )
                                                    }
                                                  >
                                                    {responseSetData?.options.map(
                                                      (option, index) => (
                                                        <>
                                                          <span
                                                            key={index}
                                                            style={{
                                                              backgroundColor:
                                                                option?.color,
                                                            }}
                                                            className={` text-white text-s font-medium me-2 px-2.5 py-0.5 rounded`}
                                                          >
                                                            {option?.label}
                                                          </span>
                                                        </>
                                                      )
                                                    )}
                                                  </div>
                                                  <button
                                                    className=" hover:text-white hover:bg-black "
                                                    onClick={() =>
                                                      getResponsetsWithID(
                                                        responseSetData?.id
                                                      )
                                                    }
                                                  >
                                                    <FaPencilAlt color="gray" />
                                                  </button>
                                                </div>
                                              )
                                            )}

                                            <div className="container mt-2">
                                              <p className="text-gray-600 text-sm">
                                                No Global Responses Sets
                                              </p>
                                              <div className="flex flex-col w-[20rem] mt-3 text-center items-center">
                                                <p>
                                                  Global Response Sets are
                                                  response sets you can reuse
                                                  across all your templates and
                                                  manage from a single place.
                                                </p>
                                                <div className="flex w-[20rem] mt-3 text-center items-center">
                                                  <button className="bg-transparent border mx-12 border-gray-200 border-md rounded-md p-2 text-indigo-600 w-full">
                                                    Create Global Response
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Title Section */}
                                          <div className="pl-4 border-l w-[30rem] text-start border-gray-300">
                                            <h1 className="text-gray-800 text-sm font-medium">
                                              Title Page Information
                                            </h1>
                                            <div>
                                              {responseOptions.map(
                                                (option, index) => (
                                                  <ul className="mt-2">
                                                    <li
                                                      onClick={() =>
                                                        handleOptionClick(
                                                          index,
                                                          option?.responseType
                                                        )
                                                      }
                                                      className={`flex p-2 items-center gap-7 ${
                                                        selectedIndex === index
                                                          ? "bg-black text-white"
                                                          : "hover:text-white hover:bg-slate-400"
                                                      }`}
                                                    >
                                                      {option?.icon}

                                                      {option?.responseType}
                                                    </li>
                                                  </ul>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {isFocused === index && (
                                  <div className="flex justify-start px-2 gap-3 items-center">
                                    <input
                                      onChange={(e) =>
                                        handleInputTemplatPageInputeChange(
                                          index,
                                          "required",
                                          e.target.checked
                                        )
                                      }
                                      checked={
                                        data.titlePageFields[index]?.required
                                      }
                                      type="checkbox"
                                    />
                                    Required
                                    <input
                                      checked={
                                        data.titlePageFields[index]
                                          ?.multipleSelection
                                      }
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleInputTemplatPageInputeChange(
                                          index,
                                          "multipleSelection",
                                          e.target.checked
                                        )
                                      }
                                    />
                                    Multiple section
                                    <p> Show assets from :</p>
                                    <input
                                      onChange={(e) =>
                                        handleInputTemplatPageInputeChange(
                                          index,
                                          "flaggedResponses",
                                          e.target.checked
                                        )
                                      }
                                      checked={
                                        data.titlePageFields[index]
                                          ?.flaggedResponses
                                      }
                                      type="checkbox"
                                    />
                                    Flagged Responses
                                  </div>
                                )}
                              </>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    </div>

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
        <button
          onClick={() => addRowToTitlePage()}
          className="bg-white text-indigo-600 border p-2 flex gap-4 mt-2 rounded-md items-center "
        >
          <FaPlus /> Add question
        </button>

        {/* <!-- drawer init and toggle --> */}
        <div className="text-center">
          {/* <button
            onClick={() => setOpenSetResponses(false)}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            type="button"
            data-drawer-target="drawer-right-example"
            data-drawer-show="drawer-right-example"
            data-drawer-placement="right"
            aria-controls="drawer-right-example"
          >
            Show right drawer
          </button> */}
        </div>
        {/* <!-- drawer component --> */}
        <div
          style={{ background: "rgb(248, 249, 252)" }}
          id="drawer-right-example"
          className={`  fixed top-0 right-0 z-40 h-screen  overflow-y-auto  transition-transform ${
            openSetResponses ? "translate-x-full" : ""
          }  bg-[248, 249, 252] w-[30rem] dark:bg-gray-800`}
          tabindex="-1"
          aria-labelledby="drawer-right-label"
        >
          <div className="p-4">
            <h1 style={{ fontSize: "30px" }}>
              <input
                type="text"
                name="TemplateTitle"
                onChange={(e) =>
                  setResponseSet({ ...responseSetData, name: e.target.value })
                }
                defaultValue={"Name of Response set"}
                class="text-start hover:text-gray-700  rounded bg-transparent  focus:text-black w-full focus:bg-white  focus:outline-blue-600 outline-none"
                placeholder=" Multiple choices "
                required
              />
            </h1>
            <div className=" flex gap-3 justify-start items-center ">
              <input
                type="checkbox"
                checked={responseSetData?.isGlobal}
                onChange={(e) =>
                  setResponseSet({
                    ...responseSetData,
                    isGlobal: e.target.checked,
                  })
                }
                className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
              />
              <label className="ms-2 text-sm font-medium text-gray-900">
                Is Global
              </label>

              <select
                onChange={(e) =>
                  setResponseSet({ ...responseSetData, type: e.target.value })
                }
                id="simple-select"
                name="options"
              >
                <option disabled selected>
                  Choose an option
                </option>
                <option value="MULTIPLE_CHOICE"> MULTIPLE_CHOICE</option>
                <option value=" SAFETY_STATUS"> SAFETY_STATUS</option>
                <option value="YES_NO"> YES_NO</option>
                <option value="COMPLIANT">COMPLIANT</option>
              </select>
              <label for="simple-select">Select an Option:</label>
            </div>

            <div className="flex items-center justify-between ">
              <p className="flex mt-[20px] text-[1rem]  items-center mb-4 text-base  text-gray-500 dark:text-gray-400">
                e.g. Yes, No, N/A
              </p>
              <div className="flex items-center  ">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  checked={scoring}
                  onChange={(e) => setScoring(e.target.checked)}
                  className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="checked-checkbox"
                  className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
                >
                  Scoring
                </label>
              </div>
            </div>
          </div>
          <button
            onClick={() => setOpenSetResponses(true)}
            type="button"
            data-drawer-hide="drawer-right-example"
            aria-controls="drawer-right-example"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>

          <div className="flex items-start flex-col ">
            <p className="  text-gray-400 text-sm px-5 ">Response</p>

            {responseSetData?.options.map((option, index) => (
              <div
                key={index}
                className="border-t border-gray-300 py-2 w-full bg-white"
              >
                <div className="flex items-center gap-2">
                  <MdDragIndicator
                    size={30}
                    color="gray"
                    className="cursor-pointer"
                  />

                  <input
                    type="text"
                    defaultValue={option.label}
                    onChange={(e) =>
                      handleChange(index, "label", e.target.value)
                    }
                    onClick={() => setFocusedIndex(index)}
                    className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                    placeholder="Response"
                  />
                  <input
                    type="color"
                    value={option.color}
                    onChange={(e) =>
                      handleChange(index, "color", e.target.value)
                    }
                    className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black focus:bg-white focus:outline-blue-600 outline-none"
                  />
                </div>
                {focusedIndex === index && (
                  <div className="flex justify-end px-2 gap-3 items-center">
                    <input
                      type="checkbox"
                      checked={option?.flagged}
                      onChange={(e) =>
                        handleChange(index, "flagged", e.target.checked)
                      }
                      className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label className="ms-2 text-sm font-medium text-gray-900">
                      Marked as flagged
                    </label>

                    {scoring && (
                      <>
                        <label className="ms-2 text-sm font-medium text-gray-900">
                          Score
                        </label>
                        <input
                          type="number"
                          value={option.score || ""}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "score",
                              e.target.value ? parseInt(e.target.value) : null
                            )
                          }
                          className="w-10 outline-none text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        />
                      </>
                    )}
                    <button>
                      <RiDeleteBin6Line size={20} className="text-indigo-600" />
                    </button>
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={handleAddResponse}
              className="flex items-center justify-start gap-2 px-3 text-md mt-3"
            >
              <FaPlus size={9} /> Add Response
            </button>
            <button
              onClick={SetResponseSet}
              className=" bg-indigo-500 m-2 text-white p-2 mt-2 rounded-md "
            >
              Save And Apply
            </button>
            {/* <button className="flex items-center justify-start gap-2 px-3 text-md mt-3  ">
      <FaPlus size={9} /> Add Response
    </button> */}
          </div>
          {/* <div class="grid grid-cols-2 gap-4">
    <a
      href="#"
      class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      Learn more
    </a>
    <a
      href="#"
      class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
      Get access
      <svg
        class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
    </a>
  </div> */}
        </div>

        {/* DRAWER FOR EDIT RESPONSE tYPE */}
        <div
          style={{ background: "rgb(248, 249, 252)" }}
          id="drawer-right-example"
          className={`  fixed top-0 right-0 z-40 h-screen  overflow-y-auto  transition-transform ${
            openEditSetResponse ? "translate-x-full" : ""
          }  bg-[248, 249, 252] w-[30rem] dark:bg-gray-800`}
          tabindex="-1"
          aria-labelledby="drawer-right-label"
        >
          <div className="p-4">
            <h1 style={{ fontSize: "30px" }}>
              <input
                type="text"
                name="TemplateTitle"
                Value={responSetUpdateState?.name}
                onChange={(e) =>
                  setResponSetUpdateState({
                    ...responSetUpdateState,
                    name: e.target.value,
                  })
                }
                class="text-start hover:text-gray-700  rounded bg-transparent  focus:text-black w-full focus:bg-white  focus:outline-blue-600 outline-none"
                placeholder=" Multiple choices"
                required
              />
            </h1>
            <div className=" flex gap-3 justify-start items-center ">
              <input
                type="checkbox"
                defaultChecked={responSetUpdateState?.global}
                contentEditable
                onChange={(e) =>
                  setResponSetUpdateState({
                    ...responSetUpdateState,
                    isGlobal: e.target.checked,
                  })
                }
                className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
              />
              <label className="ms-2 text-sm font-medium text-gray-900">
                Is Global
              </label>

              <select
                onChange={(e) =>
                  setResponSetUpdateState({
                    ...responSetUpdateState,
                    type: e.target.value,
                  })
                }
                value={responSetUpdateState?.type}
                id="simple-select"
                name="options"
              >
                <option disabled value="">
                  Choose an option
                </option>
                <option value="MULTIPLE_CHOICE">MULTIPLE_CHOICE</option>
                <option value="SAFETY_STATUS">SAFETY_STATUS</option>
                <option value="YES_NO">YES_NO</option>
                <option value="COMPLIANT">COMPLIANT</option>
              </select>

              <label for="simple-select">Select an Option:</label>
            </div>
            <div className="flex items-center justify-between ">
              <p className="flex mt-[20px] text-[1rem]  items-center mb-4 text-base  text-gray-500 dark:text-gray-400">
                e.g. Yes, No, N/A
              </p>
              <div className="flex items-center  ">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  checked={scoring}
                  onChange={(e) => setScoring(e.target.checked)}
                  className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="checked-checkbox"
                  className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
                >
                  Scoring
                </label>
              </div>
            </div>
          </div>
          <button
            onClick={() => setOpenEditResponse(true)}
            type="button"
            data-drawer-hide="drawer-right-example"
            aria-controls="drawer-right-example"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>

          <div className="flex items-start flex-col ">
            <p className="  text-gray-400 text-sm px-5 ">Response</p>

            {responSetUpdateState?.options.map((option, index) => (
              <div
                key={index}
                className="border-t border-gray-300 py-2 w-full bg-white"
              >
                <div className="flex items-center gap-2">
                  <MdDragIndicator
                    size={30}
                    color="gray"
                    className="cursor-pointer"
                  />

                  <input
                    type="text"
                    defaultValue={option?.label}
                    onChange={(e) =>
                      handleUpdateChange(index, "label", e.target.value)
                    }
                    onClick={() => setFocusedIndex(index)}
                    className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                    placeholder="Response"
                  />
                  <input
                    type="color"
                    defaultValue={option?.color}
                    onChange={(e) =>
                      handleUpdateChange(index, "color", e.target.value)
                    }
                    className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black focus:bg-white focus:outline-blue-600 outline-none"
                  />
                </div>
                {focusedIndex === index && (
                  <div className="flex justify-end px-2 gap-3 items-center">
                    <input
                      type="checkbox"
                      defaultChecked={option?.flagged}
                      onChange={(e) =>
                        handleUpdateChange(index, "flagged", e.target.checked)
                      }
                      className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label className="ms-2 text-sm font-medium text-gray-900">
                      Marked as flagged
                    </label>

                    {scoring && (
                      <>
                        <label className="ms-2 text-sm font-medium text-gray-900">
                          Score
                        </label>
                        <input
                          type="number"
                          value={option.score || ""}
                          onChange={(e) =>
                            handleUpdateChange(
                              index,
                              "score",
                              e.target.value ? parseInt(e.target.value) : null
                            )
                          }
                          className="w-10 outline-none text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        />
                      </>
                    )}
                    <button>
                      <RiDeleteBin6Line size={20} className="text-indigo-600" />
                    </button>
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={handleAddResponseUpdate}
              className="flex items-center justify-start gap-2 px-3 text-md mt-3"
            >
              <FaPlus size={9} /> Add Response
            </button>
            <button
              onClick={() => {
                SetResponseSetUpdate(responSetUpdateState?.id);
              }}
              className=" bg-indigo-500 m-2 text-white p-2 mt-2 rounded-md "
            >
              Save And Apply
            </button>
            {/* <button className="flex items-center justify-start gap-2 px-3 text-md mt-3  ">
      <FaPlus size={9} /> Add Response
    </button> */}
          </div>
          {/* <div class="grid grid-cols-2 gap-4">
    <a
      href="#"
      class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      Learn more
    </a>
    <a
      href="#"
      class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
      Get access
      <svg
        class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
    </a>
  </div> */}
        </div>
      </div>
    );
  };

  const renderTitlePage = () => (
    <div>
      <div className="flex items-center justify-between ml-10">
        <div className="flex ml-12 items-center w-64 justify-around gap-3">
          <RiArrowDropDownLine
            color="gray"
            size={80}
            className="cursor-pointer"
          />
          <h1 style={{ fontSize: "30px" }}>
            <input
              type="text"
              name="TemplateTitle"
              defaultValue={"Title Page"}
              ref={inputRef}
              className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
              placeholder="Page title"
              required
              readOnly={!isEditable} // Make input readonly unless editable
            />
          </h1>
          <FaPencilAlt
            size={30}
            color="blue"
            className="cursor-pointer"
            onClick={handleIconClick} // Trigger the input to be editable on icon click
          />
        </div>
        <div className="flex flex-col rounded-md shadow-sm gap-3 items-center bg-white p-2 ">
          <button
            // onClick={() => deletePage(page.id)}
            className="flex text-xs justify-center border  items-center flex-col"
          >
            <RiDeleteBin6Line size={20} className=" text-indigo-600" />
          </button>
        </div>
      </div>
      <div className="flex items-center text-center mx-12 text-gray-800 ml-6 text-lg ">
        <p className="flex items-center ml-24">
          The Title Page is the first page of your inspection report. You can
          <span className="text-indigo-600 ml-2 mr-2 hover:underline">
            Customize the Title Page
          </span>{" "}
          below.
        </p>
      </div>
      {renderTemplaTeTitleQuestions()}

      {/* <div className="flex gap-4 mt-5">
        <button
          // onClick={() => addRowToSection(page.id)}
          className="bg-white text-indigo-600 border p-2 flex gap-4 mt-2 rounded-md items-center "
        >
          <TbSection /> Add Question
        </button>
      </div> */}
    </div>
  );
  const renderPage = (page) => (
    <div key={page?.id}>
      <div className="flex items-center justify-between ">
        <div className="flex ml-12 items-center w-64 justify-around  gap-3 ">
          <RiArrowDropDownLine
            color="gray"
            size={80}
            className="cursor-pointer"
          />
          <h1 style={{ fontSize: "30px" }}>
            <input
              type="text"
              name="TemplateTitle"
              defaultValue={" untitled Page "}
              onChange={(e) =>
                handleInputChange(page.id, null, null, {
                  pageTitle: e.target.value,
                })
              }
              class="text-start hover:text-gray-700  rounded bg-transparent  focus:text-black w-full focus:bg-white  focus:outline-blue-600 outline-none"
              placeholder="Page title"
              required
            />
          </h1>
          <FaPencilAlt size={30} color="blue" />
        </div>
        <div className="flex flex-col rounded-md shadow-sm gap-3 items-center bg-white p-2 ">
          <button
            onClick={() => deletePage(page.id)}
            className="flex text-xs justify-center border  items-center flex-col"
          >
            <RiDeleteBin6Line size={20} className=" text-indigo-600" />
          </button>
        </div>
      </div>
      <div className="flex items-center text-center mx-12 text-gray-800 ml-6 text-lg ">
        <p className="flex items-center ml-12">
          The Title Page is the first page of your inspection report. You can
          customize the Title Page below.
        </p>
      </div>
      {page.sections.map((section) => renderSection(page.id, section))}
      <div className="flex gap-4 mt-5">
        <button
          onClick={() => addSection(page.id)}
          className="bg-white text-indigo-600 border p-2 flex gap-4 mt-2 rounded-md items-center "
        >
          <TbSection /> Add Section
        </button>
      </div>
    </div>
  );
  // const QuestionInput = React.memo(({ value, onChange, onFocus }) => {
  //   return (
  //     <input
  //       type="text"
  //       value={value}
  //       className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
  //       placeholder="Question"
  //       onChange={onChange}
  //       onFocus={onFocus}
  //     />
  //   );
  // });
  const renderSection = (pageId, section) => {
    // const toggleRespnseSet = (sectionId, questionIndex) => {
    //   setOpenSetResponses((prev) => ({
    //     ...prev,
    //     [sectionId]: {
    //       ...prev[sectionId],
    //       [questionIndex]: !prev[sectionId]?.[questionIndex], // Toggle specific dropdown for section and question
    //     },
    //   }));
    // };

    const toggleDropdown = (sectionId, questionIndex, userId) => {
      setOpenDropdowns((prev) => ({
        ...prev,
        [sectionId]: {
          ...prev[sectionId],
          [questionIndex]: !prev[sectionId]?.[questionIndex], // Toggle specific dropdown for section and question
        },
      }));
      setSetQuestionId(userId);
    };

    return (
      <div key={section?.id} className="max-h-screen w-full  bg-gray-100 p-6">
        <div className=" flex   items-start gap-2 rounded-lg">
          <div className="flex flex-col shadow-sm gap-3 bg-white p-2 ">
            <button className="flex text-xs rounded-md justify-center items-center flex-col">
              <FaPlus size={15} color="green" />
              Questions
            </button>
            <button className="flex text-xs justify-center items-center flex-col">
              <TbSection className="text-indigo-600" size={15} />
              Sections
            </button>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="w-full text-start border border-gray-200">
              {/* Header */}
              <div className="flex bg-gray-100">
                <div
                  className="border border-gray-300 px-4 py-2"
                  style={{ width: "80%" }}
                >
                  Questions
                </div>
                <div
                  className="border border-gray-300 px-4 py-2"
                  style={{ width: "40%" }}
                >
                  Type of response
                </div>
              </div>

              <Droppable droppableId="questions-list">
                {(provided) => (
                  <div
                    className="bg-white"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="mb-4">
                      {/* Section Heading */}
                      <div className="border-r border-gray-300 px-4 py-2 flex-1 flex items-center gap-6">
                        <MdDragIndicator
                          onClick={() => setIsFocused(false)}
                          size={30}
                          color="gray"
                          className="cursor-pointer"
                        />
                        <input
                          type="text"
                          name="SectionTitle"
                          defaultValue={section?.title}
                          className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                          onChange={(e) =>
                            handleInputChange(pageId, section?.id, null, {
                              title: e.target.value,
                            })
                          }
                          placeholder="section"
                          required
                          onFocus={() => setIsFocused(true)}
                        />

                        <div class="flex items-center">
                          <input
                            id="link-checkbox"
                            type="checkbox"
                            checked={section?.repeatable} // Ensure this reflects the state
                            onChange={(e) =>
                              handleInputChange(pageId, section.id, null, {
                                repeatable: e.target.checked,
                              })
                            }
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="link-checkbox"
                            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Repeatable
                          </label>
                        </div>
                      </div>
                      {/* Questions List */}
                      <div className="bg-white border border-gray-300 rounded-b-md">
                        {section?.questions.map((users, index) => (
                          <Draggable
                            key={`${users?.id}`}
                            draggableId={`${users?.id}-${users?.question}`}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex border-b border-gray-300 hover:bg-gray-50"
                                >
                                  {/* Question Column */}
                                  <div className="border-r border-gray-300 px-4 py-2 flex-1 flex items-center gap-6">
                                    <MdDragIndicator
                                      onClick={() =>
                                        setFocusedIndexPagesQuestions(null)
                                      }
                                      size={30}
                                      color="gray"
                                      className="cursor-pointer"
                                    />
                                    <input
                                      className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                                      type="text"
                                      ref={(el) =>
                                        (inputRefs.current[index] = el)
                                      }
                                      value={users?.question || ""}
                                      // className="text-start  hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none"
                                      placeholder="Question"
                                      onChange={(e) =>
                                        handleInputChange(
                                          pageId,
                                          section?.id,
                                          users?.id,
                                          {
                                            question: e.target.value,
                                          }
                                        )
                                      }
                                      required
                                      onFocus={() => setFocus(index)}
                                    />

                                    <button
                                      onClick={() =>
                                        deleteRow(
                                          pageId,
                                          section?.id,
                                          users?.id
                                        )
                                      }
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>

                                  {/* Response Type Column */}
                                  <div
                                    onClick={() => {
                                      toggleDropdown(
                                        section?.id,
                                        index,
                                        users?.id
                                      );
                                      setFocusedIndex(null);
                                    }}
                                    className="flex items-center border-gray-300 px-4 py-2 cursor-pointer relative"
                                  >
                                    <RiArrowDropDownLine
                                      color="gray"
                                      size={30}
                                      className="cursor-pointer"
                                    />

                                    <span>
                                      {users?.responseType
                                        ? users?.responseType
                                        : "Type of responses"}
                                    </span>
                                  </div>
                                </div>
                                {QuestionfocusedIndex === index && (
                                  <div className="flex justify-start px-2 gap-3 items-center">
                                    <input
                                      onChange={() =>
                                        handleInputChange(
                                          pageId,
                                          section.id,
                                          users.id,
                                          {
                                            required: !users?.required,
                                          }
                                        )
                                      }
                                      checked={users?.required}
                                      type="checkbox"
                                    />
                                    Required
                                    <input
                                      checked={users?.multipleSelection}
                                      onChange={() =>
                                        handleInputChange(
                                          pageId,
                                          section.id,
                                          users.id,
                                          {
                                            multipleSelection:
                                              !users?.multipleSelection,
                                          }
                                        )
                                      }
                                      type="checkbox"
                                    />
                                    Multiple section
                                    <p> Show assets from :</p>
                                    <input
                                      onChange={() =>
                                        handleInputChange(
                                          pageId,
                                          section.id,
                                          users.id,
                                          {
                                            flaggedResponses:
                                              !users?.flaggedResponses,
                                          }
                                        )
                                      }
                                      checked={users?.flaggedResponses}
                                      type="checkbox"
                                    />
                                    Flagged Responses
                                    <input type="checkbox" /> Time
                                    <input type="checkbox" /> Day
                                  </div>
                                )}
                              </>
                            )}
                          </Draggable>
                        ))}
                        {openDropdowns[section?.id] && (
                          <div className="bg-white absolute   z-10  right-0  p-3">
                            <div className="flex items-start h-auto justify-between gap-6  bg-white rounded-lg p-4">
                              <div className="w-full  overflow-y-scroll h-[20rem] text-start">
                                <div className="flex items-center w-[20rem] border border-gray-300 rounded-lg px-3 py-2">
                                  <FaSearch className="text-gray-500" />
                                  <input
                                    type="text"
                                    id="last_name"
                                    className="outline-none text-gray-900 text-sm w-full px-4 py-1 bg-transparent"
                                    placeholder="Search"
                                    required
                                  />
                                </div>

                                {/* Multiple Choice Section */}
                                <div className="border-b-2 pb-5">
                                  <div className="flex mt-4 items-center justify-around">
                                    <span className="text-gray-700 text-sm">
                                      Multiple Choice
                                    </span>
                                    <button
                                      onClick={() => {
                                        setOpenSetResponses(false);
                                      }}
                                      className="text-blue-800 underline cursor-pointer flex gap-2 items-center"
                                    >
                                      <FaPlus size={12} />
                                      <span className="text-sm">Response</span>
                                    </button>
                                  </div>
                                  {ResponseLists?.data?.responseSets?.map(
                                    (responseSetData) => (
                                      <div className="flex mt-3 justify-around items-start">
                                        <div
                                          onClick={(e) =>
                                            handleInputChange(
                                              pageId,
                                              section?.id,
                                              QuestionId,
                                              {
                                                responseType:
                                                  responseSetData?.type,
                                                responseSetId:
                                                  responseSetData?.id,
                                              }
                                            )
                                          }
                                        >
                                          {responseSetData?.options.map(
                                            (option, index) => (
                                              <>
                                                <span
                                                  key={index}
                                                  style={{
                                                    backgroundColor:
                                                      option?.color,
                                                  }}
                                                  className={` text-white text-s font-medium me-2 px-2.5 py-0.5 rounded`}
                                                >
                                                  {option?.label}
                                                </span>
                                                {/* <span className="bg-red-100 text-red-800 text-s font-medium me-2 px-2.5 py-0.5 rounded">
                          Bad
                        </span>
                        <span className="bg-green-100 text-green-800 text-s font-medium me-2 px-2.5 py-0.5 rounded">
                          Good
                        </span>
                        <span className="bg-yellow-100 text-yellow-800 text-s font-medium me-2 px-2.5 py-0.5 rounded">
                          Fair
                        </span> */}
                                              </>
                                            )
                                          )}
                                        </div>
                                        <button
                                          onClick={() =>
                                            getResponsetsWithID(
                                              responseSetData?.id
                                            )
                                          }
                                        >
                                          <FaPencilAlt color="gray" />
                                        </button>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div className="container mt-2">
                                  <p className="text-gray-600 text-sm">
                                    No Global Responses Sets
                                  </p>
                                  <div className="flex flex-col w-[20rem] mt-3 text-center items-center">
                                    <p>
                                      Global Response Sets are response sets you
                                      can reuse across all your templates and
                                      manage from a single place.
                                    </p>
                                    <div className="flex w-[20rem] mt-3 text-center items-center">
                                      <button className="bg-transparent border mx-12 border-gray-200 border-md rounded-md p-2 text-indigo-600 w-full">
                                        Create Global Response
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Title Section */}
                              <div className="pl-4 border-l w-[30rem] text-start border-gray-300">
                                <h1 className="text-gray-800 text-sm font-medium">
                                  Other Responses
                                </h1>
                                <div>
                                  <ul className="mt-2 h-[30rem] overflow-y-scroll ">
                                    {responseOptions.map((option, index) => (
                                      <li
                                        key={option?.responseType}
                                        onClick={() =>
                                          handleInputChange(
                                            pageId,
                                            section?.id,
                                            QuestionId,
                                            {
                                              responseType:
                                                option?.responseType,
                                            }
                                          )
                                        }
                                        className="flex items-center p-2 hover:text-white hover:bg-slate-300 mt-2 text-lg   gap-7 "
                                        style={{ cursor: "pointer" }}
                                      >
                                        {option?.icon}
                                        {option?.label}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>

          <div className="flex flex-col rounded-md shadow-sm gap-3 items-center bg-white p-2 ">
            <button
              onClick={() => deleteSection(pageId, section.id)}
              className="flex text-xs justify-center border  items-center flex-col"
            >
              <RiDeleteBin6Line size={20} className=" text-indigo-600" />
            </button>
          </div>
        </div>
        <button
          onClick={() => addRowToSection(pageId, section.id)}
          className="bg-white text-indigo-600 border p-2 flex gap-4 mt-2 rounded-md items-center "
        >
          <FaPlus /> Add question
        </button>

        {/* <!-- drawer init and toggle --> */}
        <div className="text-center"></div>
        {/* <!-- drawer component --> */}
      </div>
    );
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle the sidebar visibility
  const toggleSidebar = () => {
    // console.log('Toggle Sidebar');
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <div className="  bg-gray-100 ">
      <nav class="bg-white border-gray-200 dark:bg-gray-900 relative mt-10  border-gray-200 dark:border-gray-600">
        <div class=" flex flex-wrap items-center justify-between mx-auto p-4 ">
          <Link
            to={"#"}
            class="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <button
              type="button"
              onClick={toggleSidebar}
              class="py-2.5 text-purple-500 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Bulk Edit
            </button>
            <RiArrowGoBackFill
              size={16}
              className="cursor-pointer text-gray-400"
              onClick={goBack} // Navigate back
            />
            <TfiBackRight
              size={16}
              className="cursor-pointer text-gray-400"
              onClick={goForward} // Navigate forward
            />
          </Link>
          <div>
            {isSidebarOpen && (
              <div className="fixed top-0 right-0 w-1/3 h-full bg-gray-300 shadow-lg p-6 overflow-y-auto">
                <h2 className="text-lg font-semibold mt-20 text-left">
                  Bulk Edit
                </h2>
                <p className="mt-4 text-left">
                  Bulk add the following logic to this template.
                </p>
                <button
                  onClick={toggleSidebar}
                  className="absolute top-4 right-4 text-white hover:text-gray-700"
                >
                  Close
                </button>
              </div>
            )}
          </div>

          <div class="flex gap-8 md:order-2 items-center space-x-3 md:space-x-0 rtl:space-x-reverse">
            <span class="bg-blue-100 text-black text-md font-lg me-2 px-2.5 py-0.5 rounded bg-indigo-50 dark:text-blue-300">
              All changes saved
            </span>
            Last published: 11 Dec 2024 10:53 PM
            <button
              onClick={Publish}
              type="button"
              disabled={!isDisabled}
              className={`p-2 text-sm font-medium rounded-lg px-4 py-2 text-center border focus:outline-none focus:ring-4 
              ${
                isDisabled
                  ? "text-gray-400 bg-gray-50 border-gray-50 cursor-not-allowed"
                  : "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-300"
              }`}
              title={isDisabled ? "Button is disabled" : "Click to publish"}
            >
              Publish
            </button>
            <span
              className={`flex items-center ${
                isDisabled
                  ? "cursor-not-allowed text-gray-400"
                  : "cursor-pointer text-gray-700 hover:text-blue-600"
              }`}
              title={isDisabled ? "SVG is disabled" : "Ready to use"}
            >
              <svg
                width="1.125rem"
                height="1.125rem"
                viewBox="0 0 24 24"
                color="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isDisabled ? "fill-gray-400" : "fill-current"}`}
              >
                <path d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5Z"></path>
                <path d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"></path>
                <path d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"></path>
              </svg>
            </span>
          </div>
          <div
            class="items-center  justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-cta"
          >
            <ul class="flex hidden flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  href="#"
                  class="block py-2 px-3 md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:dark:text-blue-500"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container flex flex-col items-start mx-auto  ">
        <div className="flex items-center mt-12 ">
          <div
            style={{ border: "1px dashed rgb(191, 198, 212)" }}
            className="bg-white p-12 border-dotted border-12 rounded-md m-12 relative flex items-center justify-center hover:bg-gray-200 group"
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-50"
            />
            {data.imageUrl ? (
              <img
                src={data.imageUrl}
                alt="Profile Preview"
                className="w-48 h-48 object-cover"
              />
            ) : (
              <div className="flex flex-col items-center">
                {/* Default Icon (Visible before hover) */}
                <svg
                  width="48"
                  height="48"
                  xmlns="http://www.w3.org/2000/svg"
                  className="opacity-100 transition-opacity duration-300 group-hover:opacity-0"
                >
                  <path
                    d="M39,34 L36,34 L36,31 C36,30.44775 35.55273,30 35,30 C34.44727,30 34,30.44775 34,31 L34,34 L31,34 C30.44727,34 30,34.44775 30,35 C30,35.55225 30.44727,36 31,36 L34,36 L34,39 C34,39.55225 34.44727,40 35,40 C35.55273,40 36,39.55225 36,39 L36,36 L39,36 C39.55273,36 40,35.55225 40,35 C40,34.44775 39.55273,34 39,34 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M42,37 L37,42 L2,42 C0.89543,42 0,41.10457 0,40 L0,2 C0,0.89543 0.89543,0 2,0 L40,0 C41.10457,0 42,0.89543 42,2 L42,37 Z"
                    fill="#EEF5FF"
                  ></path>
                  <circle fill="#5E9CFF" cx="18" cy="12" r="4"></circle>
                  <path
                    d="M40.791103,32.3878833 L30.7918482,19.36966 C30.4139463,18.87678 29.5849081,18.87678 29.2070063,19.36966 L20.9649405,30.1001308 L14.7813214,22.3591948 C14.4024396,21.8839195 13.5987895,21.8839195 13.2199178,22.3591948 L5.22051392,32.3732128 C4.69807285,33.0261568 5.16230825,34 6.00121573,34 L39.998682,34 C40.8276403,34 41.2964253,33.0459845 40.791103,32.3878833 Z"
                    fill="#5E9CFF"
                  ></path>
                  <circle fill="#1ECF93" cx="38" cy="38" r="8"></circle>
                  <path
                    d="M41,37 L39,37 L39,35 C39,34.44769 38.55231,34 38,34 C37.44769,34 37,34.44769 37,35 L37,37 L35,37 C34.44769,37 34,37.44769 34,38 C34,38.55225 34.44769,39 35,39 L37,39 L37,41 C37,41.55225 37.44769,42 38,42 C38.55231,42 39,41.55225 39,41 L39,39 L41,39 C41.55231,39 42,38.55225 42,38 C42,37.44769 41.55231,37 41,37 Z"
                    fill="#FFFFFF"
                  ></path>
                </svg>

                {/* Hover Icon (Visible on hover) */}
                <svg
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-100"
                  focusable="false"
                >
                  <path
                    d="M6.293 7.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L13 4.414V16a1 1 0 11-2 0V4.414L7.707 7.707a1 1 0 01-1.414 0z"
                    fill="#545f70"
                  ></path>
                  <path
                    d="M2 15a1 1 0 011 1v4a1 1 0 001 1h16a1 1 0 001-1v-4a1 1 0 112 0v4a3 3 0 01-3 3H4a3 3 0 01-3-3v-4a1 1 0 011-1z"
                    fill="#545f70"
                  ></path>
                </svg>

                {/* Hover content text */}
                <div className="absolute opacity-0 group-hover:opacity-100 flex flex-col items-center transition-opacity duration-100 mt-6">
                  <p className="mt-2 text-gray-500">Upload Image</p>
                </div>
              </div>
            )}
          </div>
          <div typeof="textarea ">
            <p style={{ fontSize: "40px" }} className="w-full">
              <input
                type="text"
                name="TemplateTitle"
                defaultValue={"Untitled Template"}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
                className="text-start hover:text-gray-700 rounded bg-transparent focus:text-black w-full focus:bg-white focus:outline-blue-600 outline-none font-normal" // Using Tailwind's font-light class
                placeholder="Your template title"
                required
              />
            </p>

            <h6 style={{ fontSize: "20px" }} className=" w-full ">
              <input
                type="text"
                name="TemplateTitle"
                defaultValue={" Add Description"}
                class="text-start mt-2 rounded bg-transparent text-gray-400 focus:text-black w-full focus:bg-white  focus:outline-blue-600 outline-none"
                placeholder="Add Description"
                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
                required
              />
            </h6>
          </div>
          <div>
            <button
              className="  flex items-center  bg-indigo-500 text-white gap-4 p-3 "
              onClick={createTemplate}
            >
              Create Template <FaPencilAlt />
            </button>
          </div>
        </div>

        <div className=" w-full  bg-gray-100 p-6">
          {renderTitlePage()}
          {data.pages.map(renderPage)}

          <div className="text-start mt-4">
            <p
              className="text-gray-500 cursor-pointer underline "
              onClick={addPage}
            >
              Add Page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplatePage;
