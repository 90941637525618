import React, { createContext, useContext, useState, useEffect } from "react";

import useHttp from "../../common/hooks/useHttp";
import { useAuth } from "./AuthContext";
const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const { authData } = useAuth();
  const { sendRequest, loading, error } = useHttp();
  const [getTamplateList, setGetTemplate] = useState([]);
  const [InspectionLists, setInspectionList] = useState([]);
  const [assests, setAsset] = useState();
  const [assetTypes, setAssetTypes] = useState([]);
  const [users, setUsers] = useState(null);
  const [schedules, setSchedualeList] = useState();
  const [getLabelese, setGetLabels] = useState();
  const [dashboardActions, setActionsDashboard] = useState();
  const [assetFields, setAssetFields] = useState();
  const [TamplateIds, setTamplateId] = useState();
  const [sheduleMissed, setSchedualeMised] = useState();
  const [sheduleMy, setSchedualeMy] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [getOrganizationSubscriptions, setOrganizationSubscription] =
    useState();
  const [getSettingsActionType, setgetSettingsActionType] = useState();
  const [getSiteLists, setGetSitlist] = useState([]);
  const [templates, setTEmplates] = useState();
  const [labels, setLabels] = useState();
  const [ResponseLists, setResponseLists] = useState();
  // const getActionsLists = async () => {
  //   try {
  //     await sendRequest(
  //       {
  //         url: "v1/actions/list?page=0&size=10&sortBy=lastActivityAt&sortDirection=DESC",
  //         accessToken: authData.accessToken,
  //         headers: {
  //           userName: authData.userName,
  //           organizationId: authData.organizationId,
  //           deviceId: authData.deviceId,
  //         },
  //       },
  //       (response) => {
  //         setOrganizationSubscription(response);
  //         console.log(response);
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error fetching organization users:", error);
  //   }
  // };
  const getSettingsAction = async () => {
    try {
      await sendRequest(
        {
          url: "v1/actions/settings",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setgetSettingsActionType(response?.data);
          console.log(response);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getSettingsAction();
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [authData]);
  const getOrganizationSubscription = async () => {
    try {
      await sendRequest(
        {
          url: "v1/billing/subscriptions",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setOrganizationSubscription(response);
          console.log(response);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };
  const getResponseLists = async () => {
    try {
      await sendRequest(
        {
          url: "v1/response-set/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log("ResponseLists", response);
          setResponseLists(response);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };

  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getResponseLists();
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [authData]);
  const getOrganizationUsers = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/users",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setUsers(response.data.organizationUsers);
          console.log(response);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };
  const getAssetTypes = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/types/details",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setAssetTypes(response?.data?.assetTypesDetails);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const getAssetFields = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/fields",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setAssetFields(response.data.fields);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const getSiteList = async () => {
    try {
      await sendRequest(
        {
          url: "v1/site/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGetSitlist(response.data?.sites);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getTamplate = async () => {
    try {
      await sendRequest(
        {
          url: "v1/template/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGetTemplate(response);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getTamplateById = async (getTamplateById) => {
    try {
      await sendRequest(
        {
          url: `v1/template/${getTamplateById}`,
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setTamplateId(getTamplateById);
          setTEmplates(response);
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const getAssets = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setAsset(response.data.assets);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };

  const getSchedualList = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setSchedualeList(response);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  const getSheduleMissed = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/missed?lastDays=30",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setSchedualeMised(response);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };

  const getMyshedule = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/my",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setSchedualeMy(response);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  const getDashboardData = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/dashboard",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setDashboardData(response);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  const getLabels = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/dashboard",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setLabels(response);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getOrganizationSubscription();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getDashboardData();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getMyshedule();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getSheduleMissed();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getSchedualList();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getAssets();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  const getInspectionsLists = async () => {
    try {
      await sendRequest(
        {
          url: `v1/inspection/list`,
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData?.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log("inspections", response);
          setInspectionList(response?.data?.inspections);
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getInspectionsLists();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getTamplateById();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);

  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getTamplate();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  const getLabeles = async () => {
    try {
      await sendRequest(
        {
          url: `/v1/actions/settings/labels`,
          method: "GET",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setGetLabels(response);
            } else {
              console.log(response);
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const getActionsDashboard = async () => {
    try {
      await sendRequest(
        {
          url: `/v1/actions/dashboard`,
          method: "GET",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setActionsDashboard(response);
            } else {
              console.log(response);
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getActionsDashboard();
      } catch (e) {
        console.log(e, " error");
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getLabeles();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getSiteList();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getAssetTypes();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getOrganizationUsers();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  // Run
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getAssetFields();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  return (
    <ApiContext.Provider
      value={{
        loading,
        error,
        users,
        getMyshedule,
        getTamplate,
        assetTypes,
        getSiteLists,
        getTamplateList,
        sheduleMy,
        assetFields,
        schedules,
        getSchedualList,
        assests,
        TamplateIds,
        setTamplateId,
        InspectionLists,
        templates,
        dashboardData,
        getOrganizationSubscription,
        sheduleMissed,
        labels,
        dashboardActions,

        setLabels,
        getLabels,
        getSettingsAction,
        getSettingsActionType,
        setgetSettingsActionType,
        getOrganizationSubscriptions,
        getTamplateById,
        ResponseLists,
        getLabelese,
        getResponseLists,
        getOrganizationUsers,
        // getAssetTypes,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
