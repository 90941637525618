import React, { useState } from "react";
import { SlCalender } from "react-icons/sl"; // Assuming you're using Heroicons for icons.
import { useApi } from "../../context/OrganizationContaxt";
const Agenda = () => {
  const [activeTabs, setActiveTabs] = useState(["All"]); // Default 'All' is active
  const { dashboardActions } = useApi();
  console.log(dashboardActions);

  const handleTabClick = (tab) => {
    if (activeTabs.includes(tab)) {
      setActiveTabs(activeTabs.filter((t) => t !== tab)); // Unselect tab if clicked again
    } else {
      setActiveTabs([...activeTabs, tab]); // Add tab to the active state
    }
  };

  const tabs = ["All", "Inspections", "Actions", "Issues", "Training"];

  return (
    <div className="p-6 ">
      <h1 className="text-[#1f2533] mb-3 text-xl font-bold text-start">
        Agenda
      </h1>
      <div className="flex space-x-2 mb-8">
        {tabs?.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 border rounded-lg ${
              activeTabs?.includes(tab)
                ? "border-[#564be7] text-[#4740d4] bg-[#e0e4ff]"
                : "border-[#bfc6d4] text-[#1f2533] bg-white"
            } focus:outline-none`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center bg-white py-16 rounded-lg shadow-md h-[500px]">
        <SlCalender className="text-[100px] text-blue-300 mb-4" />
        <p className="text-xl font-semibold text-gray-700 mb-2">All clear!</p>
        <p className="text-gray-500 text-center">
          Scheduled items and items with due dates that are assigned to you will
          appear here.
        </p>
      </div>
    </div>
  );
};

export default Agenda;
