import React, { useState, useEffect, useRef  } from "react";
import { FaSearch, FaFolderOpen } from "react-icons/fa";
const Archieve = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const filterDropdownRef = useRef(null); // Reference for the filter dropdown
  const filterButtonRef = useRef(null); // Reference for the filter button
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [DropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [currentSelection, setCurrentSelection] = useState("Option 1");

  const initialOptions = [
      { id: 1, label: "Actions", underline: false, checked: false },
      { id: 2, label: "Doc number", underline: false, checked: false },
      { id: 3, label: "Score", underline: true, checked: false },
      { id: 4, label: "Conducted", underline: true, checked: false },
      { id: 5, label: "Completed", underline: true, checked: false },
    ];
    
    const [options, setOptions] = useState(initialOptions);
    const [filteredOptions, setFilteredOptions] = useState(initialOptions);  
      const toggleCheckbox = (id) => {
        setFilteredOptions((prevOptions) =>
          prevOptions.map((option) =>
            option.id === id ? { ...option, checked: !option.checked } : option
          )
        );
      };
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter); // Set selected filter
    setIsFilterOpen(false); // Close the dropdown
  };

  const handleRemoveFilter = () => {
    setSelectedFilter(""); // Remove the selected filter
  };

  // Close dropdown if clicked outside of filter dropdown or filter button
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current && !filterDropdownRef.current.contains(event.target) &&
        filterButtonRef.current && !filterButtonRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Example data to search from
  const inspections = [
    { id: 1, name: "Inspection 1", docNumber: "DOC123", score: "85%", conducted: "2025-01-01", completed: "2025-01-10" },
    { id: 2, name: "Inspection 2", docNumber: "DOC124", score: "90%", conducted: "2025-01-05", completed: "2025-01-15" },
    { id: 3, name: "Inspection 3", docNumber: "DOC125", score: "75%", conducted: "2025-01-10", completed: "2025-01-20" },
  ];

  // Filter inspections based on the search query and selected filter
  const filteredInspections = inspections.filter((inspection) =>
    inspection.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectionChange = (option) => {
    setCurrentSelection(option); // Set the selected option
    setDropdownOpen(false); // Optionally close the dropdown after selection
  };
  

  return (
    <div className="pt-5 pe-10">
      <div>
        <h1 className="text-gray-800 font-bold text-2xl text-start">
          Inspection archive
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                placeholder="Search"
                className="ml-2 outline-none bg-transparent"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {/* Filter button and dropdown */}
            <div className="relative">
              <button
                ref={filterButtonRef} // Attach ref to the button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover hover:bg-indigo-50 p-3 rounded-lg"
              >
                {selectedFilter ? selectedFilter : "+ Add filter"}
              </button>
              {isFilterOpen && !selectedFilter && (
                <div
                  ref={filterDropdownRef} // Attach ref to the dropdown
                  className="absolute mt-2 w-48 rounded-md shadow-lg bg-white"
                >
                  <ul className="py-1 text-gray-700">
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 1")}
                    >
                      Filter 1
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 2")}
                    >
                      Filter 2
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 3")}
                    >
                      Filter 3
                    </li>
                  </ul>
                </div>
              )}
              {/* If a filter is selected, show the "remove filter" option */}
              {selectedFilter && (
                <button
                  onClick={handleRemoveFilter}
                  className="text-red-600 text-sm ml-2"
                >
                  Remove filter
                </button>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <p className="text-gray-600 text-md">
              {filteredInspections.length === 0 
                ? "No results" 
                : `1 - ${filteredInspections.length} of ${filteredInspections.length} result`}
            </p>
            <button
              type="button"
              className="icon-button__StyledButton-iauditor__sc-1ev4xf3-1 cOPNnz"
            >
              <svg width="18" height="18" viewBox="0 0 14 14" focusable="false">
                <g transform="translate(5.542 1.458)" fill="#1f2533" fillRule="nonzero">
                  <circle transform="rotate(90 1.458 5.542)" cx="1.458" cy="5.542" r="1.458"></circle>
                  <circle transform="rotate(90 1.458 9.625)" cx="1.458" cy="9.625" r="1.458"></circle>
                  <circle transform="rotate(90 1.458 1.458)" cx="1.458" cy="1.458" r="1.458"></circle>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Header */}
      <div className="flex items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium">
        <input type="checkbox" className="mr-4" />
        <span className="flex-1 text-gray-800 underline text-center">Inspection</span>
        <span className="flex-1 text-gray-800 text-center">Actions</span>
        <span className="flex-1 text-gray-800 text-center">Doc number</span>
        <span className="flex-1 text-gray-800 underline text-center">Score</span>
        <span className="flex-1 text-gray-800 underline text-center">Conducted</span>
        <span className="flex-1 text-gray-800 underline text-center">Completed</span>
        <button
          ref={buttonRef}
          onClick={toggleDropdown}
          className="ml-4 text-gray-400 hover:text-gray-600"
        >
        <svg viewBox="0 0 24 24" width="18" height="18" fill="#545f70" xmlns="http://www.w3.org/2000/svg"><path d="M18.506 12c0 .306-.027.594-.063.882l1.9 1.485a.454.454 0 01.107.576l-1.8 3.114a.439.439 0 01-.549.198l-2.24-.9a6.915 6.915 0 01-1.522.882l-.342 2.385a.439.439 0 01-.44.378h-3.6a.439.439 0 01-.442-.378l-.342-2.385a6.575 6.575 0 01-1.52-.882l-2.242.9a.452.452 0 01-.549-.198l-1.8-3.114a.454.454 0 01.108-.576l1.9-1.485A7.138 7.138 0 015.005 12c0-.297.027-.594.063-.882L3.17 9.633a.444.444 0 01-.108-.576l1.8-3.114a.439.439 0 01.55-.198l2.24.9a6.915 6.915 0 011.521-.882l.342-2.385A.439.439 0 019.956 3h3.6c.225 0 .414.162.441.378l.342 2.385a6.575 6.575 0 011.521.882l2.241-.9a.452.452 0 01.55.198l1.8 3.114a.454.454 0 01-.109.576l-1.899 1.485c.036.288.063.576.063.882zm-9.9 0a3.153 3.153 0 003.15 3.15 3.153 3.153 0 003.15-3.15 3.153 3.153 0 00-3.15-3.15A3.153 3.153 0 008.606 12z" fill="#545f70"></path><mask id="a" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18"><path d="M18.506 12c0 .306-.027.594-.063.882l1.9 1.485a.454.454 0 01.107.576l-1.8 3.114a.439.439 0 01-.549.198l-2.24-.9a6.915 6.915 0 01-1.522.882l-.342 2.385a.439.439 0 01-.44.378h-3.6a.439.439 0 01-.442-.378l-.342-2.385a6.575 6.575 0 01-1.52-.882l-2.242.9a.452.452 0 01-.549-.198l-1.8-3.114a.454.454 0 01.108-.576l1.9-1.485A7.138 7.138 0 015.005 12c0-.297.027-.594.063-.882L3.17 9.633a.444.444 0 01-.108-.576l1.8-3.114a.439.439 0 01.55-.198l2.24.9a6.915 6.915 0 011.521-.882l.342-2.385A.439.439 0 019.956 3h3.6c.225 0 .414.162.441.378l.342 2.385a6.575 6.575 0 011.521.882l2.241-.9a.452.452 0 01.55.198l1.8 3.114a.454.454 0 01-.109.576l-1.899 1.485c.036.288.063.576.063.882zm-9.9 0a3.153 3.153 0 003.15 3.15 3.153 3.153 0 003.15-3.15 3.153 3.153 0 00-3.15-3.15A3.153 3.153 0 008.606 12z" fill="none"></path></mask></svg>
        </button>
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef} // Attach ref to the dropdown
          className="mt-2 w-48 p-4 rounded-md shadow-lg bg-white"
          style={{ position: "absolute", right: "100px" }}
        >
         <ul>
            {filteredOptions.map((item) => (
              <li
                key={item.id}
                className="flex items-center mb-2 hover:bg-gray-100 rounded-md p-1 transition"
              >
                <label className="flex items-center cursor-pointer w-full">
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => toggleCheckbox(item.id)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 rounded"
                  />
                  <span className="ml-3 text-gray-700">
                    {item.label}
                  </span>
                  {item.isNew && (
                    <span className="ml-auto bg-purple-100 text-purple-600 text-xs font-semibold px-2 py-0.5 rounded">
                      NEW
                    </span>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Inspection list */}
      <div>
        {filteredInspections.length > 0 ? (
          filteredInspections.map((inspection) => (
            <div
              key={inspection.id}
              className="flex items-center border-b px-4 py-3 text-gray-800 border border-gray-200"
            >
              <input type="checkbox" className="mr-4" />
              <span className="flex-1 text-center">{inspection.name}</span>
              <span className="flex-1 text-center">{inspection.actions}</span>
              <span className="flex-1 text-center">{inspection.docNumber}</span>
              <span className="flex-1 text-center">{inspection.score}</span>
              <span className="flex-1 text-center">{inspection.conducted}</span>
              <span className="flex-1 text-center">{inspection.completed}</span>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-16 border-2">
            <svg viewBox="0 0 138 138" fill="none" width="150" height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>            
            <p className="text-gray-600 mt-4">Try again with different search terms or filters</p>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center mt-6 space-x-4">
        <button
          className="text-gray-500 bg-gray-200 border border-gray-400 rounded p-2"
          aria-label="Previous page"
        >
          &lt;
        </button>
        <span className="text-gray-600"> 1 / 1</span>
        <button className="text-gray-500" aria-label="Next page">
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Archieve;
