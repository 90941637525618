import React, { useEffect, useState } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaCross,
  FaRecycle,
} from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import { FaUsers, FaBuilding, FaLock } from "react-icons/fa";
import { useNotification } from "../../../common/hooks/useNotification";
import useHttp from "../../../common/hooks/useHttp";
import { useApi } from "../../context/OrganizationContaxt";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
function ActionSettings({ onClose }) {
  const [isCreateTypeModalOpen, setCreateTypeModalOpen] = useState(false);
  const [isEditTypeModalOpen, setEditTypeModalOpen] = useState(false);

  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);

  const [isEditStatusDetailsModalOpen, setEditStatusDetailsModalOpen] =
    useState(false);
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const { authData } = useAuth();
  const [selectedOption, setSelectedOption] = useState("Collaborators");
  const openCreateTypeModal = () => setCreateTypeModalOpen(true);
  const closeCreateTypeModal = () => setCreateTypeModalOpen(false);
  const editTypeModal = () => setEditTypeModalOpen(true);
  const closeEdittypemodal = () => setEditTypeModalOpen(false);
  const { getSettingsActionType, getSettingsAction } = useApi();
  const [updateTypeName, setUpdateTypeName] = useState();
  const [Id, setId] = useState();
  const navigate = useNavigate();

  const [actiontypedata, setActiontypeData] = useState({
    name: "",
    isEditable: false,
    isCustom: false,
  });
  const [priorityData, setPriorityData] = useState({
    lowPriorityDays: getSettingsActionType.settings?.lowPriorityDays,
    lowPriorityUnit: getSettingsActionType.settings?.lowPriorityUnit,
    mediumPriorityDays: getSettingsActionType.settings?.mediumPriorityDays,
    mediumPriorityUnit: getSettingsActionType.settings?.mediumPriorityUnit,
    highPriorityDays: getSettingsActionType.settings?.highPriorityDays,
    highPriorityUnit: getSettingsActionType.settings?.highPriorityUnit,
  });
  const Closedstatuses =
    getSettingsActionType?.settings?.statusSettings?.filter(
      (status) => status.statusType === "CLOSED"
    );
  console.log(getSettingsActionType, "ACTIONTYPE");
  console.log(priorityData, "priority");

  const OpenStatusses = getSettingsActionType?.settings?.statusSettings?.filter(
    (status) => status.statusType === "OPEN"
  );
  const [updateStatus, setUpdateStatus] = useState();
  console.log(Closedstatuses, "get close status");
  console.log(OpenStatusses, "get status");
  const openEditStatusModal = (updatStatus) => {
    setEditStatusModalOpen(true);
    setUpdateStatus(updatStatus);
  };
  console.log(updateStatus, "update Status");

  const openEditStatusDetailsModal = () => setEditStatusDetailsModalOpen(true);
  const closeEditStatusModal = () => setEditStatusModalOpen(false);
  const [editLow, setEditLow] = useState(false);
  const [editMedium, setEditMedium] = useState(false);
  const [editHigh, setEditHigh] = useState(false);
  const [dueDate, setDueDate] = useState({ low: 7, medium: 7, high: 7 });
  const [unit, setUnit] = useState("Day");
  const [getLabels, setGetLabels] = useState();
  console.log(getLabels, "getlabels");

  const toggleEdit = (priority) => {
    if (priority === "low") setEditLow(!editLow);
    if (priority === "medium") setEditMedium(!editMedium);
    if (priority === "high") setEditHigh(!editHigh);
  };
  const [showLabelInput, setShowLabelInput] = useState(false);
  const [showUpdateLabelInput, setShowUpdateLabelInput] = useState(false);

  const [labelValue, setLbaelValue] = useState();
  const [LabelupdateValue, setLbaelUpdateValue] = useState();
  const [labelItem, setLabelItem] = useState();
  const handleUpdateLabel = (item) => {
    setLabelItem(item);
    setShowUpdateLabelInput(true);
  };
  const CreatActionType = async () => {
    try {
      await sendRequest(
        {
          url: "v1/actions/settings/types",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: actiontypedata,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getSettingsAction();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleEdit = (item) => {
    editTypeModal();
    setUpdateTypeName(item?.name);
    setId(item?.id);
  };
  const CreateLAbel = async () => {
    try {
      await sendRequest(
        {
          url: "v1/actions/settings/labels",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: labelValue,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getLabeles();
              setShowLabelInput(false);
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const updateLabel = async () => {
    try {
      await sendRequest(
        {
          url: `/v1/actions/settings/labels/${labelItem?.id}`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: LabelupdateValue,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getLabeles();
              setShowUpdateLabelInput(false);
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const DeleteLabel = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/actions/settings/labels/${id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: labelValue,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getSettingsAction();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const updateActionType = async () => {
    try {
      await sendRequest(
        {
          url: `v1/actions/settings/types/${Id}`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            name: updateTypeName,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getLabeles();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const updateActionDuePRiority = async () => {
    try {
      await sendRequest(
        {
          url: `v1/actions/settings/priority-due-dates`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            lowPriorityDays: priorityData?.lowPriorityDays,
            lowPriorityUnit: priorityData?.lowPriorityUnit,
            mediumPriorityDays: priorityData?.mediumPriorityDays,
            mediumPriorityUnit: priorityData?.mediumPriorityUnit,
            highPriorityDays: priorityData?.highPriorityDays,
            highPriorityUnit: priorityData?.highPriorityUnit,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getSettingsAction();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const getLabeles = async () => {
    try {
      await sendRequest(
        {
          url: `/v1/actions/settings/labels`,
          method: "GET",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setGetLabels(response);
            } else {
              notify(response.responseDesc, "error");
              console.log(response);
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getLabeles();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  const updateActionSettingStatus = async () => {
    try {
      await sendRequest(
        {
          url: `v1/actions/settings/status/${updateStatus?.id}`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            name: updateStatus?.statusName,
            moveToStatus: updateStatus?.moveToStatus,
            requireEvidence: updateStatus?.requireEvidence,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getSettingsAction();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
              console.log(response);
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const DeleteActiontype = async (Id) => {
    try {
      await sendRequest(
        {
          url: `v1/actions/settings/types/${Id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              // setLastActiveAt(response?.data?.action?.lastActivityAt);
              getLabeles();
              // getActionsLists();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  return (
    <div className="p-6 ">
      {/* Header */}
      <div className="flex items-center space-x-2 text-2xl  text-gray-800 mb-7">
        <IoMdArrowBack className="font-[400]"
          onClick={() => navigate(-1)}
        />
        <span>Action settings</span>
      </div>

      {/* Types Table */}
      <div className="bg-white border border-[#bfc6d4] text-start rounded-xl p-6 mb-7">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-lg font-semibold">Types</h2>
            <p className="text-base text-gray-500">
              Manage custom types and fields
            </p>
          </div>
          <span className="text-indigo-500 font-semibold text-sm bg-indigo-100 rounded-full px-2 py-1">
            NEW
          </span>
        </div>
        <table className="w-full text-left text-sm">
          <thead className="bg-[#f8f9fc] !py-2">
            <tr className="py-2">
              <th className="border-b pb-2">Type name</th>
              <th className="border-b pb-2">Active actions</th>
              <th className="border-b pb-2">Active schedules</th>
              <th className="border-b pb-2">Number of fields</th>
              <th className="border-b pb-2"></th>
              <th className="border-b pb-2"></th>
              <th className="border-b pb-2">Delete</th>
            </tr>
          </thead>
          <tbody>
            {getSettingsActionType &&
              getSettingsActionType?.settings.types.map((item, index) => (
                <tr key={index}>
                  <td className="py-2"> {item?.name} </td>
                  <td className="py-2">{item?.activeActions}</td>
                  <td className="py-2">{item?.activeSchedules}</td>
                  <td className="py-2">{item?.numberOfFields}</td>
                  <td className="py-2 text-indigo-500 cursor-pointer">
                    {item?.isEditable === true ? "Edit type" : "not Editable"}
                  </td>
                  <td
                    className="py-2 text-indigo-500 cursor-pointer"
                    onClick={() => handleEdit(item)}
                  >
                    update
                  </td>
                  <td
                    className="py-2 hover:text-indigo-500 text-red-900 cursor-pointer"
                    onClick={() => DeleteActiontype(item?.id)}
                  >
                    Delete
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-end pt-3">
          <button
            onClick={openCreateTypeModal}
            className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none"
          >
            + Create type
          </button>
        </div>
      </div>

      {/* Action Status Table */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Action status</h2>
        <p className="text-base text-gray-500 mb-4">
          Customize status names, manage who can move actions to each status,
          and make evidence required for each action.
        </p>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border-b pb-2">Status name</th>
              <th className="border-b pb-2">Move to status</th>
              <th className="border-b pb-2">Require evidence</th>
              <th className="border-b pb-2"></th>
            </tr>
          </thead>
          <tbody className="space-y-3">
            <tr className=" bg-[#f8f9fc]">
              <th className="border-b pb-2 font-normal">Open statuses</th>
            </tr>

            {OpenStatusses &&
              OpenStatusses?.map((status) => (
                <tr key={status}>
                  <td className="py-2">{status?.statusName}</td>
                  <td className="py-2">
                    <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                      {status?.moveToStatus === "ALL_USERS"
                        ? "ALL USERS"
                        : "RESTRICTION"}
                    </span>
                  </td>
                  <td className="py-2">
                    <span className="px-3 py-1 bg-gray-100 text-gray-500 rounded-md">
                      {status?.requireEvidence === "NO_EVIDENCE"
                        ? "No evidence "
                        : "ONLY_NOTES"
                        ? "ONLY NOTES"
                        : "ONLY_MEDIA"
                        ? "ONLY MEDIA"
                        : "MEDIA AND NOTES"}
                    </span>
                  </td>
                  <td
                    className="py-2 text-gray-500 cursor-pointer"
                    onClick={() => openEditStatusModal(status)}
                  >
                    <FaEdit />
                  </td>
                </tr>
              ))}

            <tr className="bg-[#f8f9fc]">
              <th className="border-b pb-2 font-normal">Closed statuses</th>
            </tr>
            {Closedstatuses &&
              Closedstatuses?.map((status) => (
                <tr key={status}>
                  <td className="py-2">{status?.statusName}</td>
                  <td className="py-2">
                    <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                      {status?.moveToStatus === "ALL_USERS"
                        ? "ALL USERS"
                        : "RESTRICTION"}
                    </span>
                  </td>
                  <td className="py-2">
                    <span className="px-3 py-1 bg-gray-100 text-gray-500 rounded-md">
                      {status?.requireEvidence === "NO_EVIDENCE"
                        ? "No evidence "
                        : "ONLY_NOTES"
                        ? "ONLY NOTES"
                        : "ONLY_MEDIA"
                        ? "ONLY MEDIA"
                        : "MEDIA AND NOTES"}
                    </span>
                  </td>
                  <td
                    className="py-2 text-gray-500 cursor-pointer"
                    onClick={() => openEditStatusModal(status)}
                  >
                    <FaEdit />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Create Type Modal */}
      {isEditTypeModalOpen && (
        <div className="fixed inset-0 top-0 bg-black bg-opacity-50 flex justify-center z-50 items-center">
          <div className="bg-white p-7 text-start rounded-lg max-w-sm w-full space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Create type</h3>
              <button
                onClick={closeEdittypemodal}
                className="text-gray-500 font-[600]"
              >
                <FaCross />
              </button>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type name
              </label>
              <input
                onChange={(e) => setUpdateTypeName(e.target.value)}
                type="text"
                value={updateTypeName}
                placeholder="action type Name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
              {/* <div className="flex gap-2">
                <label> editable</label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setActiontypeData({
                      ...actiontypedata,
                      isEditable: e.target.checked,
                    })
                  }
                  checked={actiontypedata?.isEditable}
                />
              </div>
              <div className="flex gap-2">
                <label> custom Field</label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setActiontypeData({
                      ...actiontypedata,
                      isCustom: e.target.checked,
                    })
                  }
                  checked={actiontypedata.isCustom}
                />
              </div> */}
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={closeEdittypemodal}
                className="px-4 py-2 bg-white border border-[#bfc6d4] text-[#4740d4] rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => updateActionType()}
                className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600"
              >
                update type
              </button>
            </div>
          </div>
        </div>
      )}

      {isCreateTypeModalOpen && (
        <div className="fixed inset-0 top-0 bg-black bg-opacity-50 flex justify-center z-50 items-center">
          <div className="bg-white p-7 text-start rounded-lg max-w-sm w-full space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Create type</h3>
              <button
                onClick={closeCreateTypeModal}
                className="text-gray-500 font-[600]"
              >
                <FaCross />
              </button>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type name
              </label>
              <input
                onChange={(e) =>
                  setActiontypeData({ ...actiontypedata, name: e.target.value })
                }
                type="text"
                value={actiontypedata?.name}
                placeholder="action type Name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
              <div className="flex gap-2">
                <label> editable</label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setActiontypeData({
                      ...actiontypedata,
                      isEditable: e.target.checked,
                    })
                  }
                  checked={actiontypedata?.isEditable}
                />
              </div>
              <div className="flex gap-2">
                <label> custom Field</label>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setActiontypeData({
                      ...actiontypedata,
                      isCustom: e.target.checked,
                    })
                  }
                  checked={actiontypedata.isCustom}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={closeCreateTypeModal}
                className="px-4 py-2 bg-white border border-[#bfc6d4] text-[#4740d4] rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={CreatActionType}
                className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600"
              >
                Create type
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Status Modal */}
      {isEditStatusModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-end text-start">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onClose}
          >
            X
          </div>

          {/* Modal */}
          <div className="relative bg-white w-full max-w-md h-full p-6 shadow-lg overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Edit status</h2>

            {/* Name Input */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Name
              </label>
              <input
                type="text"
                value={updateStatus?.statusName}
                onChange={(e) => {
                  setUpdateStatus({
                    ...updateStatus,
                    statusName: e.target.value,
                  });
                }}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
              />
              <p className="text-xs text-gray-500 mt-1">
                This is the default status for new actions.
              </p>
            </div>

            {/* Move to Status Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">Move to status</h3>
              <p className="text-xs text-gray-500 mb-3">
                Manage who can move actions to this status.
              </p>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    checked={updateStatus?.moveToStatus === "ALL_USERS"}
                    onChange={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        moveToStatus: "ALL_USERS",
                      });
                    }}
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  All users with edit access
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={updateStatus?.moveToStatus === "RESTRICTED"}
                    onChange={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        moveToStatus: "RESTRICTED",
                      });
                    }}
                    name="moveToStatus"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Restricted
                </label>
              </div>
            </div>

            {/* Require Evidence Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">Require evidence</h3>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                    checked={updateStatus.requireEvidence === "NO_EVIDENCE"}
                    onChange={() =>
                      setUpdateStatus({
                        ...updateStatus,
                        requireEvidence: "NO_EVIDENCE",
                      })
                    }
                  />
                  No evidence required
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    onChange={() =>
                      setUpdateStatus({
                        ...updateStatus,
                        requireEvidence: "ONLY_NOTES",
                      })
                    }
                    checked={updateStatus?.requireEvidence === "ONLY_NOTES"}
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Only notes
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    onChange={() =>
                      setUpdateStatus({
                        ...updateStatus,
                        requireEvidence: "ONLY_MEDIA",
                      })
                    }
                    checked={updateStatus?.requireEvidence === "ONLY_MEDIA"}
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Only media
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={
                      updateStatus?.requireEvidence === "NOTES_AND_MEDIA"
                    }
                    onChange={() =>
                      setUpdateStatus({
                        ...updateStatus,
                        requireEvidence: "NOTES_AND_MEDIA",
                      })
                    }
                    name="requireEvidence"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Notes and media
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-start space-x-2">
              <button
                onClick={updateActionSettingStatus}
                className="px-4 py-2 bg-[#6559ff] text-white rounded-md hover:bg-purple-700"
              >
                Save and apply
              </button>
              <button
                onClick={() => setEditStatusModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditStatusDetailsModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-end text-start">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onClose}
          >
            X
          </div>

          {/* Modal */}
          <div className="relative bg-white w-full max-w-md h-full p-6 shadow-lg overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Edit action details</h2>

            {/* Move to Status Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">
                {" "}
                Edit detail fields
              </h3>
              <p className="text-xs text-gray-500 mb-3">
                Manage who can edit the detail fields for each action.
              </p>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    defaultChecked
                    onChange={(e) =>
                      setUpdateStatus({
                        ...updateStatus,
                        moveToStatus: "ALL_USERS",
                      })
                    }
                    checked={updateStatus?.moveToStatus === "ALL_USERS"}
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  All users with edit access
                </label>
                <label className="flex items-center">
                  <input
                    checked={updateStatus?.moveToStatus === "RESTRICTED"}
                    type="radio"
                    onChange={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        moveToStatus: "RESTRICTED",
                      });
                    }}
                    name="moveToStatus"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Restricted
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-start space-x-2">
              <button className="px-4 py-2 bg-[#6559ff] text-white rounded-md hover:bg-purple-700">
                Save and apply
              </button>
              <button
                onClick={() => setEditStatusDetailsModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Detail Fields Section */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Detail fields</h2>
        <p className="text-sm text-gray-500 mb-4">
          Manage who can edit the detail fields for each action.
        </p>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border-b pb-2">Fields</th>
              <th className="border-b pb-2">Edit detail fields</th>
              <th className="border-b pb-2"></th>
            </tr>
          </thead>
          <tbody
            onClick={openEditStatusDetailsModal}
            className="hover:bg-[#ecedfe] cursor-pointer"
          >
            <tr>
              <td className="py-2">
                title, description, site, assignee, priority, due date, label,
                recurrence, asset, and custom fields
              </td>
              <td className="py-2">
                <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                  All users
                </span>
              </td>
              <td className="py-2 text-gray-500 cursor-pointer">
                <FaEdit />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Priority-based Due Dates Section */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Priority-based due dates</h2>
        <p className="text-sm text-gray-500 mb-4">
          Configure the default due dates based on priority.
        </p>

        <div className="space-y-4">
          {/* Low Priority */}
          <div className="flex items-center space-x-4">
            <button onClick={() => toggleEdit("low")} className="text-gray-500">
              {editLow ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">Low</span>
            {editLow ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  defaultValue={
                    getSettingsActionType?.settings?.lowPriorityDays
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      lowPriorityDays: e.target.value,
                    })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  defaultValue={
                    getSettingsActionType?.settings?.lowPriorityUnit
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      lowPriorityUnit: e.target.value,
                    })
                  }
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option value={"DAYS"}>Day</option>
                  <option value={"WEEKS"}>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("low")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={updateActionDuePRiority}
                  className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600"
                >
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">
                {getSettingsActionType?.settings?.lowPriorityDays} {unit}
              </span>
            )}
            <button onClick={() => toggleEdit("low")} className="text-gray-500">
              <FaEdit />
            </button>
          </div>

          {/* Medium Priority */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleEdit("medium")}
              className="text-gray-500"
            >
              {editMedium ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">Medium</span>
            {editMedium ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  defaultValue={
                    getSettingsActionType?.settings?.mediumPriorityDays
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      mediumPriorityDays: e.target.value,
                    })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  defaultValue={
                    getSettingsActionType?.settings?.mediumPriorityUnit
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      mediumPriorityUnit: e.target.value,
                    })
                  }
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option value={"DAYS"}>Day</option>
                  <option value={"WEEKS"}>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("medium")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={updateActionDuePRiority}
                  className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600"
                >
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">
                {getSettingsActionType?.settings?.mediumPriorityDays} Days
              </span>
            )}
            <button
              onClick={() => toggleEdit("medium")}
              className="text-gray-500"
            >
              <FaEdit />
            </button>
          </div>

          {/* High Priority */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleEdit("high")}
              className="text-gray-500"
            >
              {editHigh ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">High</span>
            {editHigh ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  defaultValue={
                    getSettingsActionType?.settings?.highPriorityDays
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      highPriorityDays: e.target.value,
                    })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  defaultValue={
                    getSettingsActionType?.settings?.highPriorityUnit
                  }
                  onChange={(e) =>
                    setPriorityData({
                      ...priorityData,
                      highPriorityUnit: e.target.value,
                    })
                  }
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option value={"DAYS"}>Day</option>
                  <option value={"WEEKS"}>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("high")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={updateActionDuePRiority}
                  className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600"
                >
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">
                {getSettingsActionType?.settings?.highPriorityDays} {unit}
              </span>
            )}
            <button
              onClick={() => toggleEdit("high")}
              className="text-gray-500"
            >
              <FaEdit />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white text-start border border-[#bfc6d4 rounded-lg p-6 mb-7">
        <h2 className="text-lg font-semibold mb-2">Access</h2>
        <p className="text-sm text-gray-500 mb-4">
          The following access rules only apply to
          <a href="#" className="text-indigo-600 underline">
            standalone actions
          </a>
          .
        </p>

        <div className="space-y-4">
          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("All users")}
          >
            <FaUsers className="text-gray-500" />
            <span className="text-gray-700">All users</span>
            <span className="text-sm text-gray-500">
              (Anyone in my organization)
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "All users"}
              onChange={() => setSelectedOption("All users")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>

          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("Site")}
          >
            <FaBuilding className="text-gray-500" />
            <span className="text-gray-700">Site</span>
            <span className="text-sm text-gray-500">
              <a href="#" className="text-indigo-600 underline">
                Learn more
              </a>
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "Site"}
              onChange={() => setSelectedOption("Site")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>

          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("Collaborators")}
          >
            <FaLock className="text-gray-500" />
            <span className="text-gray-700">Collaborators</span>
            <span className="text-sm text-gray-500">
              (Creator and assignee)
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "Collaborators"}
              onChange={() => setSelectedOption("Collaborators")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>
        </div>

        <div className="mt-4 p-3 bg-blue-50 text-sm text-gray-700 border-l-4 border-blue-400 rounded-md">
          <p>
            <span className="text-indigo-600">ℹ</span> If you create an action
            in an inspection, the action will follow that{" "}
            <a href="#" className="text-indigo-600 underline">
              inspection's access rules
            </a>
            .
          </p>
        </div>
      </div>

      {/* Labels Section */}
      <div className="bg-white text-start border border-[#bfc6d4 rounded-lg p-6 mb-7">
        <h2 className="text-lg font-semibold mb-2">Labels</h2>
        <p className="text-sm text-gray-500 mb-4">
          Organize your actions further with customizable labels.
        </p>
        {getLabels &&
          getLabels?.data?.labels?.map((item, index) => (
            <div className="border gap-3 mb-3 flex items-center justify-start rounded-lg p-4 text-gray-500 text-start">
              {item?.name}{" "}
              <FaEdit
                cursor={"pointer"}
                onClick={() => handleUpdateLabel(item)}
              />
              <MdDeleteForever
                size={20}
                cursor={"pointer"}
                onClick={() => DeleteLabel(item?.id)}
              />
            </div>
          ))}

        <button
          onClick={() => {
            setShowLabelInput(true);
          }}
          className="mt-4 flex items-center px-4 py-2 bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100"
        >
          <span className="text-lg">+</span>
          <span className="ml-2">Create label</span>
        </button>
        {showUpdateLabelInput && (
          <div>
            <div className="w-full max-w-md">
              <label
                htmlFor="beautiful-input"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                update Label
              </label>
              <input
                type="text"
                defaultValue={labelItem?.name}
                onChange={(e) => setLbaelUpdateValue(e.target.value)}
                id="beautiful-input"
                placeholder="Enter your name"
                className="w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm bg-white text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
              />

              <div className="flex justify-end mt-2 space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition duration-300"
                  onClick={() => setShowUpdateLabelInput(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300"
                  onClick={updateLabel}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {showLabelInput && (
          <div>
            <div className="w-full max-w-md">
              <label
                htmlFor="beautiful-input"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Label Name
              </label>
              <input
                type="text"
                onChange={(e) => setLbaelValue(e.target.value)}
                id="beautiful-input"
                placeholder="Enter your name"
                className="w-full px-4 py-3 rounded-lg border border-gray-300 shadow-sm bg-white text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
              />

              <div className="flex justify-end mt-2 space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition duration-300"
                  onClick={() => setShowLabelInput(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300"
                  onClick={CreateLAbel}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionSettings;
